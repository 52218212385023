import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ConfigurationPaths } from '@gipi-paths/configuration.paths';
import { GIPIAbstractService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class ConfigurationConfigurationService extends GIPIAbstractService {
    constructor(baseService) {
        super(ConfigurationPaths.configuration, 'v1');
        this.baseService = baseService;
        /** Se trocar as permissões enquanto estiver na tela que usa a scanntech */
        this.updated$ = new BehaviorSubject(false);
    }
    findScanntech(version) {
        return this.baseService.httpClient.get(this.url(`scanntech`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(_ => of(null)));
    }
    saveScanntech(entity, version) {
        return this.baseService.httpClient.post(this.url(`scanntech`, version), entity, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
ConfigurationConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationConfigurationService_Factory() { return new ConfigurationConfigurationService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: ConfigurationConfigurationService, providedIn: "root" });
