export class RegistrationPaths {
    public static accounting: string = 'registration/accounting/v1';
    public static application: string = 'registration/application';
    public static audit: string = 'registration/audit/v1';
    public static bankAccount: string = 'registration/bank-account/v1';
    public static benefitClub: string = 'registration/benefit-club';
    public static bankAccountTransfer: string = 'registration/bank-account-transfer/v1';
    public static bankSlipConfiguration: string = 'registration/bank-slip-configuration/v1';
    public static cardAdministrator: string = 'registration/card-administrator';
    public static chargeType: string = 'registration/charge-type';
    public static city: string = 'registration/city';
    public static clientClassification: string = 'registration/client-classification';
    public static client: string = 'registration/client';
    public static company: string = 'registration/company/v1';
    public static connectConfig: string = 'registration/connect-config';
    public static connectConsult: string = 'registration/connect-consult';
    public static consultCnpj: string = 'registration/consult-cnpj/v1';
    public static costCenterAssessment: string = 'registration/cost-center-assessment/v1';
    public static costCenterAssessmentCostCenter: string = 'registration/cost-center-assessment-cost-center/v1';
    public static costCenter: string = 'registration/cost-center/v1';
    public static country: string = 'registration/country';
    public static employee: string = 'registration/employee';
    public static file: string = 'registration/file';
    public static health: string = 'registration/public/check/health';
    public static lineOfBusiness: string = 'registration/line-of-business/v1';
    public static paymentMethod: string = 'registration/payment-method';
    public static phoneType: string = 'registration/phone-type/v1';
    public static postingCategory: string = 'registration/posting-category/v1';
    public static product: string = 'registration/product';
    public static productGrouping: string = 'registration/product-grouping';
    public static productType: string = 'registration/product-type';
    public static provider: string = 'registration/provider';
    public static reasonDevolution: string = 'registration/reason-devolution/v1';
    public static socialNetworkType: string = 'registration/social-network-type/v1';
    public static state: string = 'registration/state';
    public static unitMeasurement: string = 'registration/unit-measurement';
    public static manufacturer: string = 'registration/manufacturer';
    public static department: string = 'registration/department';
    public static employeeRole: string = 'registration/employee-role';
    public static intermediary: string = 'registration/intermediary';
    public static transporter: string = 'registration/transporter';
    public static codeAnp: string = 'registration/code-anp';
    public static codeCest: string = 'registration/code-cest';
    public static codeNcm: string = 'registration/code-ncm';
    public static productOrigin: string = 'registration/product-origin';
}

