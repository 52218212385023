import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialTransactionConciliationConsultDTO } from '@gipi-financial/bill-conciliation/models/dto/transaction-conciliation-consult.dto';
import { FinancialTransactionConciliationService } from '@gipi-financial/bill-conciliation/services/transaction-concilitation.service';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialChargeTypeService } from '@gipi-financial/charge-type/services/charge-type.service';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialPostingCategoryOperation } from '@gipi-financial/posting-category/enums/operation.enum';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialProviderSelectDTO } from '@gipi-financial/provider/models/dto/provider-select.dto';
import { FinancialProviderService } from '@gipi-financial/provider/services/provider.service';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { ArrayUtil, AuthenticationService, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, NumberUtil, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';

export type AddBillInstallmentType = 'NEW' | 'UPDATE' | 'DIFFERENCE' | 'INTEREST_AND_FINE';

export interface AddBillInstallmentData {
    billInstallmentType: AddBillInstallmentType;
    isBillReceivable: boolean;
    billInstallmentUpdate: FinancialBillInstallment;
    transactionConciliation: FinancialTransactionConciliationConsultDTO;
    bankAccount: FinancialBankAccount;
    paymentOrReceivementDate: Date;
}

@Component({
    selector: 'gipi-generate-bill-receivable-dialog',
    templateUrl: './add-bill-installment-dialog.component.html',
    styleUrls: ['add-bill-installment-dialog.component.scss'],
})
export class AddBillInstallmentDialogComponent extends GIPIAbstractComponent implements OnInit {

    private _userLoggedToken: OAuthUser;

    public billInstallmentType: AddBillInstallmentType = 'NEW';

    public isBillReceivable: boolean = true;

    public billInstallment: FinancialBillInstallment = new FinancialBillInstallment();

    public receivementDate: Date;

    public chargeTypeList: FinancialChargeType[] = [];

    public billInstallmentChargeTypeList: FinancialChargeType[] = [];

    /** Valor líquido da conta */
    public billInstallmentAmountNet: number = 0;

    public isLoad: boolean = false;

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const typeOperation: FinancialPostingCategoryOperation = !this.isBillReceivable ? 'DEBIT' : 'CREDIT';
        const result: PageDTO<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue<FinancialPostingCategorySelectDTO>(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', typeOperation, true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }

        const result: PageDTO<FinancialClientSelectDTO> = await this._clientService.findByValue<FinancialClientSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    clientFn = (obj: FinancialClientSelectDTO) => this._clientService.getDescription(obj);

    providerFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }

        const result: PageDTO<FinancialProviderSelectDTO> = await this._providerService.findByValue<FinancialProviderSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    providerFn = (obj: FinancialProviderSelectDTO) => this._providerService.getDescription(obj);

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _billInstallmentService: FinancialBillInstallmentService,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _clientService: FinancialClientService,
        private _providerService: FinancialProviderService,
        private _chargeTypeService: FinancialChargeTypeService,
        private _transactionConciliationService: FinancialTransactionConciliationService,
        private _authenticationService: AuthenticationService<OAuthUser>,
        @Inject(MAT_DIALOG_DATA) public data: AddBillInstallmentData = {
            billInstallmentType: 'NEW',
            isBillReceivable: true,
            billInstallmentUpdate: null,
            transactionConciliation: null,
            bankAccount: null,
            paymentOrReceivementDate: null,
        },
        public dialogRef: MatDialogRef<AddBillInstallmentDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    async ngOnInit(): Promise<void> {
        this.isLoad = true;
        super.ngOnInit();

        this.billInstallmentType = this.data.billInstallmentType;
        this.isBillReceivable = this.data.isBillReceivable;

        this._userLoggedToken = this._authenticationService.token.user;

        this.receivementDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);

        if (this.data.billInstallmentType !== 'UPDATE') {
            this._newBillInstallment();
        }
        if ((this.data.billInstallmentType === 'UPDATE') || (this.data.billInstallmentType === 'INTEREST_AND_FINE')) {
            this.billInstallment = ObjectUtil.clone(this.data.billInstallmentUpdate);
        }

        await this._findChargeType();

        this.setAmountNet();

        this.isLoad = false;
    }

    private async _findChargeType(): Promise<void> {
        if (this.isBillReceivable) {
            this._chargeTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                this.billInstallmentChargeTypeList = page.content.filter(ct => ct.type === 'BANK_SLIP' || ct.type === 'STORE_CREDIT');

                let chargeTypeAux: FinancialChargeType = null;
                if (this._getExistInstallmentCreatedByConciliation()) {
                    chargeTypeAux = this.data.transactionConciliation.installmentListInternal.filter(b => b.createdByConciliation || b.createdByDifferenceConciliation)[0].chargeTypeReceivement;
                }

                if (ObjectUtil.isNull(chargeTypeAux)) {
                    this.chargeTypeList = page.content.filter(ct => ct.type === 'MONEY' || ct.type === 'TRANSFER' || ct.type === 'DEPOSIT' || ct.type === 'PIX');
                } else {
                    this.chargeTypeList = page.content.filter(ct => ct.type === chargeTypeAux.type);
                    this.billInstallment.chargeTypeReceivement = chargeTypeAux;
                }
            });
        } else {
            this.billInstallmentChargeTypeList = await this._chargeTypeService.findByTypes(['MONEY', 'STORE_CREDIT', 'DEBIT_CARD', 'ACCOUNT_DEBIT', 'TRANSFER', 'OTHER', 'DEPOSIT']).toPromise();
        }
    }

    private _getExistInstallmentCreatedByConciliation(): boolean {
        if (ArrayUtil.isEmpty(this.data.transactionConciliation.installmentListInternal)) {
            return false;
        }
        return this.data.transactionConciliation.installmentListInternal.filter(b => b.createdByConciliation || b.createdByDifferenceConciliation).length > 0;
    }

    private _newBillInstallment(): void {
        this.billInstallment.bill = new FinancialBill();
        this.billInstallment.bankAccount = ObjectUtil.clone(this.data.bankAccount);
        this.billInstallment.conciled = true;
        this.billInstallment.userId = this._userLoggedToken.id;
        this.billInstallment.bill.type = this.isBillReceivable ? 'RECEIVABLE' : 'PAYABLE';
        this.billInstallment.status = this.isBillReceivable ? 'RECEIVABLE' : 'PAYABLE';
        this.billInstallment.conciled = true;
        this.billInstallment.createdByConciliation = true;

        this.billInstallment.description = this.data.transactionConciliation.description;
        this.billInstallment.issuanceDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
        this.billInstallment.dueDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
        this.billInstallment.paymentDate = null;
        this.billInstallment.dischargeDate = null;
        this.billInstallment.dischargeAmount = 0;

        this.billInstallment.interest = 0;
        this.billInstallment.fine = 0;
        this.billInstallment.addition = 0;
        this.billInstallment.discount = 0;
        this.billInstallment.amount = 0;

        if (this.billInstallmentType === 'NEW') {
            const amount: number = ((this.data.transactionConciliation.value > 0)
                ? this.data.transactionConciliation.value
                : (this.data.transactionConciliation.value * (-1)));

            this.billInstallment.amount = amount;
        } else if (this.billInstallmentType === 'UPDATE') {

        } else if (this.billInstallmentType === 'DIFFERENCE') {
            this.billInstallment.createdByDifferenceConciliation = true;

            const amount: number = ((this.data.transactionConciliation.differenceValue > 0)
                ? this.data.transactionConciliation.differenceValue
                : (this.data.transactionConciliation.differenceValue * (-1)));

            this.billInstallment.amount = amount;
        }
    }

    public setAmountNet(): void {
        // Faz isso pois os campos são obrigatórios, mais é permitido passar 0. Então se o campo tiver null já seta 0 no atributo.
        this.billInstallment.interest = (ObjectUtil.isNull(this.billInstallment.interest) ? 0 : this.billInstallment.interest);
        this.billInstallment.fine = (ObjectUtil.isNull(this.billInstallment.fine) ? 0 : this.billInstallment.fine);
        this.billInstallment.addition = (ObjectUtil.isNull(this.billInstallment.addition) ? 0 : this.billInstallment.addition);
        this.billInstallment.discount = (ObjectUtil.isNull(this.billInstallment.discount) ? 0 : this.billInstallment.discount);

        let amountNetAux: number = this.billInstallment.amount;
        if (NumberUtil.isPositive(this.billInstallment.interest)) {
            amountNetAux += this.billInstallment.interest;
        }
        if (NumberUtil.isPositive(this.billInstallment.fine)) {
            amountNetAux += this.billInstallment.fine;
        }
        if (NumberUtil.isPositive(this.billInstallment.addition)) {
            amountNetAux += this.billInstallment.addition;
        }
        if (NumberUtil.isPositive(this.billInstallment.discount)) {
            amountNetAux -= this.billInstallment.discount;
        }

        if (amountNetAux >= 0) {
            this.billInstallmentAmountNet = Number(amountNetAux.toFixed(2));
        } else {
            this.billInstallmentAmountNet = this.billInstallment.amount;
        }
    }

    public validateDateIsGreaterThanTransaction(): void {
        const issuanceDate: Date = new Date(moment(this.billInstallment.issuanceDate).format('yyyy/MM/DD'));
        const dueDate: Date = new Date(moment(this.billInstallment.dueDate).format('yyyy/MM/DD'));

        if (DateUtil.isGreaterThan(new Date(issuanceDate), this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date))) {
            this.addWarningMessage('Data da emissão não pode ser superior a data do pagamento');
            return;
        }

        if (DateUtil.isGreaterThan(new Date(issuanceDate), new Date(dueDate))) {
            this.addWarningMessage('Data da emissão não pode ser superior a data do vencimento');
            return;
        }
    }

    public saveBillInstallment(): void {
        try {
            this.isLoad = true;
            this.loading = true;

            // if (!this.isBillReceivable) {
            //     this.billInstallment.chargeType = ObjectUtil.clone(this.billInstallment.chargeTypeReceivement);
            // }

            this._billInstallmentService.validate(this.billInstallment);
            if (this.isBillReceivable && ObjectUtil.isNewModel(this.billInstallment.chargeTypeReceivement)) {
                this.addWarningMessage('Tipo de cobrança do recebimento é obrigatório e não foi informado');
                return;
            }

            if ((this.billInstallmentType === 'NEW') || this.billInstallmentType === 'DIFFERENCE') {
                if (ArrayUtil.isEmpty(this.data.transactionConciliation.installmentListInternal)) {
                    this.data.transactionConciliation.installmentListInternal = [];
                }
                this.billInstallment.orderId = this.data.transactionConciliation.installmentListInternal.length + 1;
                this.data.transactionConciliation.installmentListInternal.push(this.billInstallment);
            } else if (this.billInstallmentType === 'UPDATE') {
                const indexInstallment: number = this.data.transactionConciliation.installmentListInternal
                    .findIndex(i => (i.id === this.billInstallment.id) || (i.orderId === this.billInstallment.orderId) || (i === this.billInstallment));

                if (indexInstallment >= 0) {
                    this.data.transactionConciliation.installmentListInternal.splice(indexInstallment, 1, this.billInstallment);
                }
            }

            this.close('RELOAD_TABLE');
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
