import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import * as moment_ from 'moment';
const moment = moment_;

import { ConfigurationScanntechUrl } from '@gipi-configuration/configuration/models/scanntech-url.model';
import { ConfigurationScanntech } from '@gipi-configuration/configuration/models/scanntech.model';
import { ConfigurationConfigurationService } from '@gipi-configuration/configuration/services/configuration.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, ArrayUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './integration-scanntech-dialog.component.html',
    styleUrls: ['./integration-scanntech-dialog.component.scss'],
})
export class IntegrationScanntechDialogComponent extends GIPIAbstractComponent implements OnInit {

    public scanntechConfig: ConfigurationScanntech = new ConfigurationScanntech();

    public panelScanntechOptionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public panelScanntechOptionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    public panelScanntechBtnViewPassword: boolean = false;

    public timeForSendingPendingClosings: string = '';

    public get panelScanntechIsNewRecord(): Observable<boolean> {
        return of(ObjectUtil.isNull(this.scanntechConfig) || ObjectUtil.isNewModel(this.scanntechConfig));
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _configurationService: ConfigurationConfigurationService,
        public dialogRef: MatDialogRef<IntegrationScanntechDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._findScanntechConfig();
    }

    private _findScanntechConfig(): void {
        try {
            this.loading = true;

            this.panelScanntechOptionActiveSituationValue = 'ENABLED';
            this.scanntechConfig.salesQuantityPerLot = 200;
            this.scanntechConfig.intervalBetweenSyncs = 10;
            this.scanntechConfig.urlList = [];

            this._configurationService.findScanntech().toPromise().then(entity => {
                if (!ObjectUtil.isNull(entity)) {
                    this.scanntechConfig = ObjectUtil.clone(entity);
                    this.panelScanntechOptionActiveSituationValue = (this.scanntechConfig.enabled ? 'ENABLED' : 'DISABLED');
                    this.timeForSendingPendingClosings = this.scanntechConfig.timeForSendingPendingClosings || '';
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.addErrorMessage(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _isValid(): boolean {
        if (!NumberUtil.isPositive(this.scanntechConfig.companyCode)) {
            this.addWarningMessage('Campo código da empresa é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.scanntechConfig.storeCode)) {
            this.addWarningMessage('Campo código da loja é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.scanntechConfig.intervalBetweenSyncs)) {
            this.addWarningMessage('Campo intervalo de sincronização é obrigatório e não foi informado');
            return false;
        }
        if (this.scanntechConfig.intervalBetweenSyncs < 1) {
            this.addWarningMessage('Campo intervalo de sincronização não pode ser inferior a 1');
            return false;
        }
        if (!NumberUtil.isPositive(this.scanntechConfig.salesQuantityPerLot)) {
            this.addWarningMessage('Campo quantidade vendas por lote é obrigatório e não foi informado');
            return false;
        }
        if (this.scanntechConfig.salesQuantityPerLot < 1) {
            this.addWarningMessage('Campo quantidade vendas por lote não pode ser inferior a 1');
            return false;
        }
        if (this.scanntechConfig.salesQuantityPerLot > 300) {
            this.addWarningMessage('Campo quantidade vendas por lote não pode ser superior a 300');
            return false;
        }
        if (StringUtil.isEmpty(this.timeForSendingPendingClosings.trim())) {
            this.addWarningMessage('Campo horário envio fechamento é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.scanntechConfig.dataSendingStartDate) || !DateUtil.isValid(this.scanntechConfig.dataSendingStartDate)) {
            this.addWarningMessage('Campo data inicio da sincronização é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.scanntechConfig.userName.trim())) {
            this.addWarningMessage('Campo usuário é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.scanntechConfig.password.trim())) {
            this.addWarningMessage('Campo senha é obrigatório e não foi informado');
            return false;
        }
        if (ArrayUtil.isEmpty(this.scanntechConfig.urlList)) {
            this.addWarningMessage('Lista de URls é obrigatório e não foi informado');
            return false;
        }
        if (!ArrayUtil.isEmpty(this.scanntechConfig.urlList)) {
            const hasEmptyUrl: boolean = this.scanntechConfig.urlList.some(e => StringUtil.isEmpty(e.url));
            if (hasEmptyUrl) {
                this.addWarningMessage('Lista de URls é obrigatório e não foi informado');
                return false;
            }
            if (this.scanntechConfig.urlList.length > 3) {
                this.addWarningMessage('Quantidade de URls não pode ser superior a 3');
                return false;
            }
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;

            this.scanntechConfig.enabled = (this.panelScanntechOptionActiveSituationValue === 'ENABLED');
            this.scanntechConfig.timeForSendingPendingClosings = moment(this.timeForSendingPendingClosings, "HHmm").format("HH:mm");

            this._configurationService.saveScanntech(this.scanntechConfig).toPromise().then(response => {
                if (!ObjectUtil.isNull(response)) {
                    this.loading = false;
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                    if (ObjectUtil.isNull(this._configurationService.updated$)) {
                        this._configurationService.updated$ = new BehaviorSubject(false);
                    }
                    this._configurationService.updated$.next(true);

                    this.close();
                }
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public scanntechDeleteUrl(scanntechUrl: ConfigurationScanntechUrl): void {
        if (UUIDUtil.isValid(scanntechUrl.id)) {
            this.scanntechConfig.urlList.splice(this.scanntechConfig.urlList.findIndex(e => e.id === scanntechUrl.id), 1);
        } else {
            this.scanntechConfig.urlList.splice(this.scanntechConfig.urlList.findIndex(e => e.key === scanntechUrl.key), 1);
        }

        if ((scanntechUrl.defaultURL) && (!ArrayUtil.isEmpty(this.scanntechConfig.urlList))) {
            this.scanntechConfig.urlList[0].defaultURL = true;
        }
    }

    public scanntechAddNewUrl(): void {
        const scanntechUrl = new ConfigurationScanntechUrl();
        scanntechUrl.key = this.scanntechConfig.urlList.length;

        if ((ArrayUtil.isEmpty(this.scanntechConfig.urlList) || this.isCreating) && scanntechUrl.key === 0) {
            scanntechUrl.defaultURL = true;
        }

        this.scanntechConfig.urlList.push(scanntechUrl);
    }

    public scanntechValidateDefaultUrl(scanntechUrl: ConfigurationScanntechUrl): void {
        this.scanntechConfig.urlList.map(e => e.defaultURL = false);

        setTimeout(() => {
            this.scanntechConfig.urlList.filter(e => e === scanntechUrl).map(e => e.defaultURL = true);
        }, 10);
    }

}
