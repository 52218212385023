import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialFilterByTypeDateEnum } from '@gipi-financial/bill/enums/filter-by-type-date.enum';
import { FinancialBillFilterDTO } from '@gipi-financial/bill/models/dto/bill.filter.dto';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialProviderService } from '@gipi-financial/provider/services/provider.service';
import { CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIAppliedFilter, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class FindBillDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, _clientService, _providerService, _bankAccountService, _postingCategoryService, dialogRef, data) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._clientService = _clientService;
        this._providerService = _providerService;
        this._bankAccountService = _bankAccountService;
        this._postingCategoryService = _postingCategoryService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.filter = new FinancialBillFilterDTO();
        this.page = new GIPIPageModel();
        this.columns = [];
        this.billInstallmentListSelected = [];
        this.allTypesChecked = true;
        this.indeterminateType = false;
        // Contas a receber
        this.receivableChecked = false;
        this.receivedChecked = false;
        this.receivableOverdueChecked = false;
        this.receivedPartialChecked = false;
        // Contas a pagar
        this.payableOverdueChecked = false;
        this.payableChecked = false;
        this.paidChecked = false;
        this.selected = false;
        this.indeterminate = false;
        this.filterByTypeDateEnum = FinancialFilterByTypeDateEnum;
        this.appliedFilters = [];
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
        this.providerFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._providerService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.providerFn = (obj) => this._providerService.getDescription(obj);
        this.bankAccountFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10, { property: 'description', direction: 'asc' }).toPromise();
            return result;
        });
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const typeOperation = !(this.data.billType === 'RECEIVABLE') ? 'DEBIT' : 'CREDIT';
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', typeOperation, true).toPromise();
            return result;
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.columns = this._createTableBillInstallmentColumns();
            _super.ngOnInit.call(this);
            this.filter.filterByTypeDate = 'DUE_DATE';
            for (let i = 0; i < this.data.installmentListSelected.length; i++) {
                this.data.installmentListSelected[i].selected = true;
                this.select(this.data.installmentListSelected[i], false);
            }
        });
    }
    _createTableBillInstallmentColumns() {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .sortable(true)
                .align('center center')
                .width(12)
                .value((obj) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .sortable(true)
                .value((obj) => obj.description)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
            TableColumnBuilder.instance()
                .property('person.name')
                .name(this.data.billType === 'RECEIVABLE' ? 'Cliente' : 'Fornecedor')
                .sortable(true)
                .value((obj) => {
                const configuration = this.baseService.sessionStorageService.get('configuration');
                if (ObjectUtil.isNull(configuration)) {
                    return obj.name;
                }
                if (!configuration.showBusinessNameInReceiptQuery) {
                    return obj.name;
                }
                if (!StringUtil.isEmpty(obj.fantasyName)) {
                    return obj.fantasyName;
                }
                return obj.name;
            })
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .sortable(true)
                .value((obj) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(10)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .sortable(true)
                .align('center center')
                .width(10)
                .value((obj) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(10)
                .template(this.statusTemplate)
                .action((obj) => {
                obj.selected = !obj.selected;
                this.select(obj, true);
            })
                .build(),
        ];
    }
    setColorStatus(entity) {
        switch (entity.status) {
            case 'RECEIVABLE':
                return '#b4b4b4';
            case 'RECEIVED':
                return '#56b910';
            case 'RECEIVED_PARTIAL':
                return '#62757f';
            case 'GROUPED':
                return '#8256D0';
            case 'RENEGOTIATED':
                return '#2bd7d9';
            case 'RECEIVABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE':
                return '#b4b4b4';
            case 'PAID':
                return '#56b910';
            default:
                return '#b4b4b4';
        }
    }
    setHintStatus(entity) {
        switch (entity.status) {
            case 'RECEIVABLE':
                return 'A receber';
            case 'RECEIVED':
                return 'Recebida';
            case 'RECEIVED_PARTIAL':
                return 'Recebida parcialmente';
            case 'GROUPED':
                return 'Agrupada';
            case 'RENEGOTIATED':
                return 'Renegociada';
            case 'RECEIVABLE_OVERDUE':
                return 'A receber atrasada';
            case 'PAYABLE_OVERDUE':
                return 'A pagar atrasada';
            case 'PAYABLE':
                return 'A pagar';
            case 'PAID':
                return 'Paga';
            default:
                return 'Não identificado';
        }
    }
    clearAppliedFilters() {
        this.appliedFilters = [];
    }
    setAppliedFilters() {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();
        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new GIPIAppliedFilter(description, 'begin_and_end_date'));
        }
        else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new GIPIAppliedFilter(description, 'end_date'));
            }
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Cliente', 'client'));
        }
        if (!ObjectUtil.isNull(this.filter.provider)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Fornecedor', 'provider'));
        }
        if (!ObjectUtil.isNull(this.filter.bankAccount)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Conta bancária', 'bank_account'));
        }
        if (!StringUtil.isEmpty(this.filter.documentNumber)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Número do documento', 'document_number'));
        }
        if (this.filter.amount) {
            this.appliedFilters.push(new GIPIAppliedFilter('Valor', 'amount'));
        }
        if (!ObjectUtil.isNull(this.filter.postingCategory)) {
            this.appliedFilters.push(new GIPIAppliedFilter('Categoria', 'category'));
        }
    }
    removeAppliedFilter(filter) {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                this.filter.clientId = null;
                break;
            }
            case 'provider': {
                this.filter.provider = null;
                this.filter.providerId = null;
                break;
            }
            case 'bank_account': {
                this.filter.bankAccount = null;
                this.filter.bankAccountId = null;
                break;
            }
            case 'document_number': {
                this.filter.documentNumber = '';
                break;
            }
            case 'amount': {
                this.filter.amount = null;
                break;
            }
            case 'category': {
                this.filter.postingCategory = null;
                this.filter.postingCategoryId = null;
                break;
            }
        }
        this.find(null);
    }
    find(pageEvent) {
        try {
            this.loading = true;
            this.filter.conciledOnly = this.data.typeConciliation === 'TRANSACTION_CONCILIATION';
            this.filter.billetConciliation = this.data.typeConciliation === 'BILLET_CONCILIATION';
            this.filter.type = this.data.billType;
            this._setStatusEnumList();
            if (this.allTypesChecked) {
                this.filter.billStatusList = [];
            }
            if (this.filter.begin && !DateUtil.isValid(new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data inicial não é uma data válida');
                return;
            }
            if (this.filter.end && !DateUtil.isValid(new Date(this.filter.end))) {
                this.loading = false;
                this.addWarningMessage('Data final não é uma data válida');
                return;
            }
            if (this.filter.begin && !this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data final não informada');
                return;
            }
            if (!this.filter.begin && this.filter.end) {
                this.loading = false;
                this.addWarningMessage('Data inicial não informada');
                return;
            }
            if (DateUtil.isLessThan(new Date(this.filter.end), new Date(this.filter.begin))) {
                this.loading = false;
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }
            if (!ObjectUtil.isNull(this.filter.client) && !ObjectUtil.isNewModel(this.filter.client)) {
                this.filter.clientId = Number(this.filter.client.id);
            }
            if (!ObjectUtil.isNull(this.filter.bankAccount) && !ObjectUtil.isNewModel(this.filter.bankAccount)) {
                this.filter.bankAccountId = Number(this.filter.bankAccount.id);
            }
            if (!ObjectUtil.isNull(this.filter.postingCategory) && !ObjectUtil.isNewModel(this.filter.postingCategory)) {
                this.filter.postingCategoryId = Number(this.filter.postingCategory.id);
            }
            // const existNumber: number = Number(this.filter.researchField ? this.filter.researchField.replace(',', '.') : 0);
            // if (!StringUtil.isEmpty(this.filter.researchField)) {
            //     this.filter.amount = NumberUtil.isPositive(existNumber) ? existNumber : null;
            //     this.filter.researchField = NumberUtil.isPositive(existNumber) ? null : this.filter.researchField;
            // }
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = 5;
                this.filter.offset = pageEvent.pageIndex * 5;
            }
            else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }
            this.setAppliedFilters();
            this.service.findAll(this.filter).toPromise().then(page => {
                const listContentAux = page.content.filter(b => ((b.status === 'RECEIVED_PARTIAL') || (!b.conciled)
                    || (!b.createdByConciliation)) && !this._installemtHasUsed(b));
                page.content = [];
                page.content = listContentAux;
                page.content.forEach((installment) => {
                    const installmentSelected = this.billInstallmentListSelected.find(i => i.id === installment.id);
                    if (!ObjectUtil.isNewModel(installmentSelected)) {
                        installment.selected = true;
                        this.select(installment, false);
                    }
                });
                this.page = page;
                this.validateAllSelected();
                if (this.filter.filterByTypeDate === 'DISCHARGE_DATE') {
                    this.filter.filterByTypeDate = (this.data.billType === 'RECEIVABLE') ? 'DISCHARGE_DATE_RECEIVEMENT' : 'DISCHARGE_DATE_PAYMENT';
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        }
        catch (e) {
            this.handleError(e);
        }
    }
    _installemtHasUsed(billInstallment) {
        return this.data.billInstallmentListUsed.filter(installmentId => installmentId === billInstallment.id).length > 0;
    }
    _setStatusEnumList() {
        this.filter.billStatusList = [];
        if (this.receivableChecked) {
            this.filter.billStatusList.push('RECEIVABLE');
        }
        if (this.receivedChecked) {
            this.filter.billStatusList.push('RECEIVED');
        }
        if (this.payableChecked) {
            this.filter.billStatusList.push('PAYABLE');
        }
        if (this.paidChecked) {
            this.filter.billStatusList.push('PAID');
        }
        if (this.receivableOverdueChecked) {
            this.filter.billStatusList.push('RECEIVABLE_OVERDUE');
        }
        if (this.payableOverdueChecked) {
            this.filter.billStatusList.push('PAYABLE_OVERDUE');
        }
    }
    clear() {
        this.filter = new FinancialBillFilterDTO();
        this.clearAppliedFilters();
        this.find();
    }
    getLabelFilterBankAccount() {
        if (this.data.billType === 'RECEIVABLE') {
            return this.filter.filterByTypeDate === 'DISCHARGE_DATE_RECEIVEMENT' ? 'Conta bancária do recebimento' : 'Conta bancária';
        }
        else {
            return this.filter.filterByTypeDate === 'DISCHARGE_DATE_PAYMENT' ? 'Conta bancária do pagamento' : 'Conta bancária';
        }
    }
    checkAllStatus(type) {
        if (type === 'RECEIVABLE') {
            this.receivableChecked = false;
            this.receivedChecked = false;
            this.receivedPartialChecked = false;
            this.receivableOverdueChecked = false;
        }
        else if (type === 'PAYABLE') {
            this.payableOverdueChecked = false;
            this.payableChecked = false;
            this.paidChecked = false;
        }
        this.indeterminateType = false;
    }
    validateAllReceivableStatusSelected() {
        if (this.receivableChecked
            && this.receivedChecked
            && this.receivableOverdueChecked
            && this.receivedPartialChecked) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        }
        else if (this.receivableChecked
            || this.receivedChecked
            || this.receivableOverdueChecked
            || this.receivedPartialChecked) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        }
        else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }
    }
    validateAllPayableStatusSelected() {
        if (this.payableOverdueChecked
            && this.payableChecked
            && this.paidChecked) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        }
        else if (this.payableOverdueChecked
            || this.payableChecked
            || this.paidChecked) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        }
        else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }
    }
    checkAll() {
        this.page.content.forEach(billInstallment => {
            billInstallment.selected = this.selected;
            this.select(billInstallment, false);
        });
        this.validateAllSelected();
    }
    select(billInstallment, validate) {
        if (billInstallment.selected && !this._installemtHasUsed(billInstallment)) {
            const alreadySelected = this.billInstallmentListSelected.find(i => i.id === billInstallment.id);
            if (ObjectUtil.isNewModel(alreadySelected)) {
                this.billInstallmentListSelected.push(billInstallment);
            }
        }
        else {
            this.billInstallmentListSelected.splice(this.billInstallmentListSelected.findIndex(p => p.id === billInstallment.id), 1);
        }
        if (validate) {
            this.validateAllSelected();
        }
    }
    validateAllSelected() {
        const list = this.page.content.filter(billInstallment => billInstallment.selected);
        this.selected = list.length > 0;
        this.indeterminate = (list.length > 0 && list.length !== this.page.content.length) || this.billInstallmentListSelected.length > 0;
    }
    confirm() {
        this.dialogRef.close(this.billInstallmentListSelected);
    }
    close() {
        this.dialogRef.close(this.data.installmentListSelected);
    }
}
