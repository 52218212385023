<gipi-abstract-form id="dialogScanntechAuditDetails"
                    name="dialogScanntechAuditDetails"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogScanntechAuditDetails">
        {{ dialogTitle$ | async }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">

        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px"
                 fxFlex="30">
                <gipi-dropdown-menu label="Status"
                                    id="dropdownMenuStatus"
                                    name="dropdownMenuStatus"
                                    #dropdownMenuStatus
                                    [placeholder]="allStatusChecked ? 'Todas as opções selecionadas' : (indeterminateStatusChecked ? 'Opções selecionadas' : 'Selecionar')"
                                    [required]="true"
                                    [disabled]="loading"
                                    [showTrigger]="true"
                                    [trigger]="getTriggerStatus()"
                                    fxFlex
                                    fxFlex.lt-md="100">
                    <div class="btn-display-content"
                         style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                        <itss-checkbox label="Todos"
                                       color="primary"
                                       [(ngModel)]="allStatusChecked"
                                       [(indeterminate)]="indeterminateStatusChecked"
                                       (change)="checkAllStatusSelected()">
                        </itss-checkbox>
                        <itss-checkbox label="Erros"
                                       color="primary"
                                       [(ngModel)]="errorStatusChecked"
                                       (change)="validateAllStatusSelected()">
                        </itss-checkbox>
                        <itss-checkbox label="Pendentes"
                                       color="primary"
                                       [(ngModel)]="pendingStatusChecked"
                                       (change)="validateAllStatusSelected()">
                        </itss-checkbox>
                        <itss-checkbox label="Enviados"
                                       color="primary"
                                       [(ngModel)]="sentStatusChecked"
                                       (change)="validateAllStatusSelected()">
                        </itss-checkbox>
                    </div>
                </gipi-dropdown-menu>
            </div>

            <gipi-button label="Filtrar"
                         gipi-primary
                         [disabled]="loading"
                         (click)="findAll(null)"
                         fxFlex="10"
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>

        <itss-table #table
                    [expandable]="true"
                    [columnExpandableFixed]="true"
                    [ignoreChildrenExpandRow]="true"
                    [loading]="loading"
                    [columns]="columns"
                    [paginator]="true"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageSize]="10"
                    [paginatorPageIndex]="page.number"
                    [data]="page.content"
                    (page)="findAll($event)">
            <ng-template #expanded
                         let-entity>
                <div class="detail">
                    <ng-container *ngIf="entity?.error">
                        <div class="column-detail"
                             style="max-width: 30%;">
                            <strong>Código</strong>
                            <span> {{ entity?.error?.codeError }} </span>
                        </div>
                        <div class="column-detail">
                            <strong>Descrição</strong>
                            <span> {{ entity?.error?.error }} </span>
                        </div>
                    </ng-container>

                    <div *ngIf="!entity?.error"
                         class="no-records">
                        <mat-icon>sentiment_very_dissatisfied</mat-icon>
                        <span>Nenhum registro foi encontrado</span>
                    </div>
                </div>
            </ng-template>
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>

</gipi-abstract-form>

<ng-template #statusTemplate
             let-entity>
    <itss-icon class="icon-table"
               icon="fiber_manual_record"
               [tooltip]="scanntechAuditStatusEnum[entity.status]"
               [color]="setColorStatus(entity.status)">
    </itss-icon>
</ng-template>