import { catchError, map, takeUntil } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationBankAccountService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.bankAccount, 'v1', baseService);
        this.baseService = baseService;
    }
    findByValue(value, page, size, sort, version, type) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        let lType = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lType}${lSort}&value=${lValue}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    validateCheckingAccount(entity) {
        if (ObjectUtil.isNewModel(entity.bank)
            || StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.modality)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.bank.code !== '999') {
            if (ObjectUtil.isNull(entity.agency)
                || ObjectUtil.isNull(entity.account)) {
                throw new Error('Campos obrigatórios (*) não informados');
            }
        }
    }
    existConciliation(id) {
        return this.baseService.httpClient.get(this.url(`exist-conciliation/${String(id)}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    existMovements(bankAccountId) {
        return this.baseService.httpClient.get(this.url(`exist-movements/${String(bankAccountId)}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationBankAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationBankAccountService_Factory() { return new RegistrationBankAccountService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationBankAccountService, providedIn: "root" });
