import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { SaleScanntechAuditOrigin, SaleScanntechAuditOriginEnum } from '@gipi-sale/scanntech-audit/enums/scanntech-audit-origin.enum';
import { SaleScanntechAuditStatus, SaleScanntechAuditStatusEnum } from '@gipi-sale/scanntech-audit/enums/scanntech-audit-status.enum';
import { SaleScanntechDetailedAuditConsultDTO } from '@gipi-sale/scanntech-audit/models/dto/scanntech-detailed-audit-consult.dto';
import { SaleScanntechDetailedAuditFilterDTO } from '@gipi-sale/scanntech-audit/models/dto/scanntech-detailed-audit-filter.dto';
import { SaleScanntechAuditService } from '@gipi-sale/scanntech-audit/services/scanntech-audit.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIFormFieldComponent, GIPIPageModel, GIPISortModel, GIPIUuid, ObjectUtil, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';
import { BehaviorSubject } from 'rxjs';

type TypeDetail = ('RESEND_REQUEST' | 'DAILY_CLOSING' | 'PROMOTIONS_CONSULTATION' | 'SALE_SHIPPING');

export interface IntegrationScanntechAuditDetailsData {
    originList: SaleScanntechAuditOrigin[];
    dailyClosingId?: GIPIUuid;
    saleId?: GIPIUuid;
    scanntechIntegrationAuditId?: GIPIUuid;
}

@Component({
    selector: 'gipi-integration-scanntech-audit-details-dialog',
    templateUrl: './integration-scanntech-audit-details-dialog.component.html',
    styleUrls: ['./integration-scanntech-audit-details-dialog.component.scss']
})
export class IntegrationScanntechAuditDetailsDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('search', { static: true }) search: GIPIFormFieldComponent;
    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public filter: SaleScanntechDetailedAuditFilterDTO = this._newFilter();
    public page: GIPIPageModel<SaleScanntechDetailedAuditConsultDTO> = this._newPage();

    public columns: TableColumnDTO[] = [];

    public allStatusChecked: boolean = true;
    public indeterminateStatusChecked: boolean = false;
    public errorStatusChecked: boolean = true;
    public pendingStatusChecked: boolean = true;
    public sentStatusChecked: boolean = true;

    public scanntechAuditOriginEnum: typeof SaleScanntechAuditOriginEnum = SaleScanntechAuditOriginEnum;
    public scanntechAuditStatusEnum: typeof SaleScanntechAuditStatusEnum = SaleScanntechAuditStatusEnum;

    public dialogTitle$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        protected service: SaleScanntechAuditService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<IntegrationScanntechAuditDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IntegrationScanntechAuditDetailsData = null,
    ) {
        super(baseService, activatedRoute);
        this._setDialogTitle();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.columns = this._createTableColumns();

        if (!ObjectUtil.isNull(this.data)) {
            this.filter.originList = this.data.originList;
            this.filter.dailyClosingId = this.UUIDIsValid(this.data.dailyClosingId) ? this.data.dailyClosingId : null;
            this.filter.saleId = this.UUIDIsValid(this.data.saleId) ? this.data.saleId : null;
            this.filter.scanntechIntegrationAuditId = this.UUIDIsValid(this.data.scanntechIntegrationAuditId) ? this.data.scanntechIntegrationAuditId : null;
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _setDialogTitle(): void {
        if (
            ObjectUtil.isNull(this.data) ||
            (!ObjectUtil.isNull(this.data) && ArrayUtil.isEmpty(this.data.originList))
        ) {
            this.dialogTitle$.next('Detalhes');
        }

        const typeDetail: TypeDetail = this._getTypeDetails();
        let dialogTitle: string = 'Detalhes';
        switch (typeDetail) {
            case 'RESEND_REQUEST': {
                dialogTitle = 'Detalhes do pedido de consulta';
                break;
            }
            case 'DAILY_CLOSING': {
                dialogTitle = 'Detalhes do fechamento diário';
                break;
            }
            case 'PROMOTIONS_CONSULTATION': {
                dialogTitle = 'Detalhes da consulta de promoção';
                break;
            }
            case 'SALE_SHIPPING': {
                dialogTitle = 'Detalhes da venda';
                break;
            }
            default: {
                dialogTitle = 'Detalhes';
                break;
            }
        }

        this.dialogTitle$.next(dialogTitle);
    }

    private _getTypeDetails(): TypeDetail {
        const typeDetailMap: Record<TypeDetail, SaleScanntechAuditOrigin[]> = {
            'RESEND_REQUEST': ['RESEND_REQUEST_CONSULTATION'],
            'DAILY_CLOSING': ['DAILY_CLOSING_RESEND', 'DAILY_CLOSING_SHIPPING'],
            'PROMOTIONS_CONSULTATION': ['PROMOTIONS_CONSULTATION'],
            'SALE_SHIPPING': ['SALES_RESEND', 'SALES_SHIPPING'],
        };

        for (const [typeDetail, origins] of Object.entries(typeDetailMap)) {
            if (this.data.originList.every(origin => origins.includes(origin))) {
                return typeDetail as TypeDetail;
            }
        }

        return null;
    }

    private _newFilter(): SaleScanntechDetailedAuditFilterDTO {
        const filter: SaleScanntechDetailedAuditFilterDTO = new SaleScanntechDetailedAuditFilterDTO();
        filter.dailyClosingId = null;
        filter.originList = [];
        filter.statusList = [];
        filter.saleId = null;
        filter.scanntechIntegrationAuditId = null;
        filter.sorts = [new GIPISortModel('date', 'DESC')];
        return filter;
    }

    private _setStatusInFilter(): void {
        this.filter.statusList = [];
        if (this.errorStatusChecked) {
            this.filter.statusList.push('ERROR');
        }
        if (this.pendingStatusChecked) {
            this.filter.statusList.push('PENDING');
        }
        if (this.sentStatusChecked) {
            this.filter.statusList.push('SENT');
        }
    }

    private _newPage(): GIPIPageModel<SaleScanntechDetailedAuditConsultDTO> {
        const page: GIPIPageModel<SaleScanntechDetailedAuditConsultDTO> = new GIPIPageModel();
        page.content = [];
        page.number = 0;
        return page;
    }

    private _createTableColumns(): TableColumnDTO[] {
        const typeDetail: TypeDetail = this._getTypeDetails();

        return [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .sortable(true)
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('origin')
                .name('Origem')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => !StringUtil.isEmpty(obj.origin) ? this.scanntechAuditOriginEnum[obj.origin] : '')
                .align('start center')
                .width(typeDetail === 'SALE_SHIPPING' ? 15 : '')
                .build(),
            TableColumnBuilder.instance()
                .property('clientName')
                .name('Cliente')
                .visible(typeDetail === 'SALE_SHIPPING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => !StringUtil.isEmpty(obj.clientName) ? obj.clientName : '')
                .align('start center')
                .build(),
            TableColumnBuilder.instance()
                .property('paymentMethodDescription')
                .name('Forma de pagamento')
                .visible(typeDetail === 'SALE_SHIPPING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => !StringUtil.isEmpty(obj.paymentMethodDescription) ? obj.paymentMethodDescription : '')
                .align('start center')
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('N° Documento')
                .visible(typeDetail === 'SALE_SHIPPING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('totalAmountCanceled')
                .name('Total cancelado')
                .visible(typeDetail === 'DAILY_CLOSING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => CurrencyUtil.transform(obj.totalAmountCanceled, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('totalAmountNet')
                .name('Total líquido')
                .visible(typeDetail !== 'PROMOTIONS_CONSULTATION')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => CurrencyUtil.transform(obj.totalAmountNet, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('totalSalesQuantityCanceled')
                .name('Qtd. vendas canceladas')
                .visible(typeDetail === 'DAILY_CLOSING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => obj.totalSalesQuantityCanceled || 0)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('totalSalesQuantity')
                .name('Qtd. vendas')
                .visible(typeDetail === 'DAILY_CLOSING')
                .value((obj: SaleScanntechDetailedAuditConsultDTO) => obj.totalSalesQuantity || 0)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .template(this.statusTemplate)
                .align('center center')
                .width(7)
                .build(),
        ];
    }

    public setColorStatus(status: SaleScanntechAuditStatus): string {
        switch (status) {
            case 'ERROR': return '#D14014';
            case 'PENDING': return '#D6A000';
            case 'SENT': return '#258E14';
            default: return '#b4b4b4';
        }
    }

    public getTriggerStatus(): number {
        let countSelected: number = 0;
        if (this.errorStatusChecked) {
            countSelected++;
        }
        if (this.pendingStatusChecked) {
            countSelected++;
        }
        if (this.sentStatusChecked) {
            countSelected++;
        }
        return countSelected;
    }

    public checkAllStatusSelected(): void {
        this.errorStatusChecked = this.allStatusChecked;
        this.pendingStatusChecked = this.allStatusChecked;
        this.sentStatusChecked = this.allStatusChecked;
        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.errorStatusChecked &&
            this.pendingStatusChecked &&
            this.sentStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.errorStatusChecked ||
            this.pendingStatusChecked ||
            this.sentStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    public findAll(pageEvent: any): void {
        try {
            this.loading = true;

            if (ObjectUtil.isNull(this.page)) {
                this.page = this._newPage();
            }
            if (ObjectUtil.isNull(this.filter)) {
                this.filter = this._newFilter();
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;

                if (!ObjectUtil.isNull(pageEvent.sort)) {
                    this.filter.sorts = [
                        new GIPISortModel(
                            pageEvent.sort.active,
                            StringUtil.isEmpty(pageEvent.sort.direction) ? 'DESC' : pageEvent.sort.direction.toUpperCase()
                        ),
                    ];
                }
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 10;
            }

            this._setStatusInFilter();

            this.service.findDetails(this.filter).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

}
