import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBank } from '@gipi-financial/bank/models/bank.model';
import { FinancialBankService } from '@gipi-financial/bank/services/bank.service';
import { FinancialCheckReceivedConciliationDTO } from '@gipi-financial/bill-conciliation/models/dto/check-received-concilition.dto';
import { FinancialTransactionConciliationConsultDTO } from '@gipi-financial/bill-conciliation/models/dto/transaction-conciliation-consult.dto';
import { FinancialTransactionConciliationService } from '@gipi-financial/bill-conciliation/services/transaction-concilitation.service';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { ArrayUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, NumberUtil, ObjectUtil, PhoneUtil, StringUtil } from '@gipisistemas/ng-core';

export type AddCheckReceivedType = 'NEW' | 'UPDATE' | 'DIFFERENCE' | 'INTEREST_AND_FINE';

export interface AddCheckReceivedData {
    checkReceivedType: AddCheckReceivedType;
    checkReceivedUpdate: FinancialCheckReceivedConciliationDTO;
    transactionConciliation: FinancialTransactionConciliationConsultDTO;
    bankAccount: FinancialBankAccount;
    compensateDate: Date;
}

@Component({
    templateUrl: './add-check-received-dialog.component.html',
    styleUrls: ['./add-check-received-dialog.component.scss']
})
export class AddCheckReceivedDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public checkReceivedType: AddCheckReceivedType = 'NEW';

    public entity: FinancialCheckReceivedConciliationDTO = this._newEntity();

    public bankAccountList: FinancialBankAccount[] = [];
    public compensateDate: Date = null;

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }

        const result: GIPIPageModel<FinancialClientSelectDTO> = await this._clientService.findByValue<FinancialClientSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    clientFn = (obj: FinancialClientSelectDTO) => this._clientService.getDescription(obj);

    bankFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialBank> = await this._bankService.findByValue(value, page, 50, { property: 'name', direction: 'asc' }).toPromise();
        return result;
    };
    bankFn = (obj: FinancialBank) => `${obj.code} - ${obj.name}`;

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue<FinancialPostingCategorySelectDTO>(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _clientService: FinancialClientService,
        private _bankService: FinancialBankService,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _transactionConciliationService: FinancialTransactionConciliationService,
        private _bankAccountService: FinancialBankAccountService,
        @Inject(MAT_DIALOG_DATA) public data: AddCheckReceivedData = {
            checkReceivedType: 'NEW',
            checkReceivedUpdate: null,
            transactionConciliation: null,
            bankAccount: null,
            compensateDate: null,
        },
        public dialogRef: MatDialogRef<AddCheckReceivedDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        super.ngOnInit();

        this.checkReceivedType = this.data.checkReceivedType;
        this.compensateDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);

        this.entity.amount = this.data.transactionConciliation.value;
        this.entity.issuanceDate = this.compensateDate;
        this.entity.dueDate = this.compensateDate;
        this.entity.date = this.compensateDate;

        if ((this.data.checkReceivedType === 'UPDATE') || (this.data.checkReceivedType === 'INTEREST_AND_FINE')) {
            this.entity = ObjectUtil.clone(this.data.checkReceivedUpdate);
        }

        this._setBankAccount();
        this._setClient();
        this._setPostingCategory();

        this.loading = false;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _newEntity(): FinancialCheckReceivedConciliationDTO {
        const entity: FinancialCheckReceivedConciliationDTO = new FinancialCheckReceivedConciliationDTO();
        entity.clientId = null;
        entity.bankAccountId = null;
        entity.bankId = null;
        entity.phone = '';
        return entity;
    }

    private async _setClient(): Promise<void> {
        if (ObjectUtil.isNull(this.data.checkReceivedUpdate)) {
            this.entity.client = null;
            this.entity.clientId = null;
            return;
        }

        const client: FinancialClient = await this._clientService.getOne(this.data.checkReceivedUpdate.clientId).toPromise().catch(e => {
            this.handleError(e);
            return null;
        });

        if (!ObjectUtil.isNull(client)) {
            this.entity.client = FinancialClientSelectDTO.transformToDTO(client);
            this.entity.clientId = this.entity.client.id;
        }
    }

    private async _setPostingCategory(): Promise<void> {
        if (ObjectUtil.isNull(this.data.checkReceivedUpdate)) {
            this.entity.postingCategory = null;
            this.entity.postingCategoryId = null;
            return;
        }

        const postingCategory: FinancialPostingCategory = await this._postingCategoryService.getOne(this.data.checkReceivedUpdate.postingCategoryId).toPromise().catch(e => {
            this.handleError(e);
            return null;
        });

        if (!ObjectUtil.isNull(postingCategory)) {
            this.entity.postingCategory = ObjectUtil.clone(postingCategory);
            this.entity.postingCategoryId = this.entity.postingCategory.id;
        }
    }

    private async _setBankAccount(): Promise<void> {
        const bankAccount: FinancialBankAccount = await this._bankAccountService.getOne(this.data.bankAccount.id).toPromise().catch(e => {
            this.handleError(e);
            return null;
        });

        if (!ObjectUtil.isNull(bankAccount)) {
            this.bankAccountList = [ObjectUtil.clone(bankAccount)];
            this.entity.bankAccount = ObjectUtil.clone(bankAccount);
            this.entity.bankAccountId = this.entity.bankAccount.id;
        }
    }

    private _isValid(): boolean {
        if (StringUtil.isEmpty(this.entity.cpfOrCnpjIssuer)) {
            this.addWarningMessage('Campo CPF/CNPJ é obrigatório e não foi informado');
            return false;
        }
        if ((this.entity.cpfOrCnpjIssuer.length !== 11) && (this.entity.cpfOrCnpjIssuer.length !== 14)) {
            this.addWarningMessage('Campo CPF/CNPJ foi informado incorretamente');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.issuer)) {
            this.addWarningMessage('Campo emitente é obrigatório e não foi informado');
            return false;
        }
        if ((!StringUtil.isEmpty(this.entity.phone)) && !PhoneUtil.isValid(this.entity.phone)) {
            this.addWarningMessage('Campo telefone foi informado incorretamente');
            return false;
        }
        if (!NumberUtil.isPositive(this.entity.checkNumber)) {
            this.addWarningMessage('Campo nº cheque é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.entity.amount)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.issuanceDate)) {
            this.addWarningMessage('Campo data de emissão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.dueDate)) {
            this.addWarningMessage('Campo data de vencimento é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNewModel(this.entity.postingCategory)) {
            this.addWarningMessage('Campo categoria é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNewModel(this.entity.bankAccount)) {
            this.addWarningMessage('Campo conta bancária para depósito é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public saveCheckReceived(): void {
        try {
            if (!this._isValid()) {
                return;
            }

            this.loading = true;
            this.entity.bankAccountId = this.entity.bankAccount.id;
            this.entity.bankAccount = null;

            this.entity.bankId = null;
            if (!ObjectUtil.isNull(this.entity.bank)) {
                this.entity.bankId = this.entity.bank.id;
                this.entity.bank = null;
            }

            this.entity.clientId = null;
            if (!ObjectUtil.isNull(this.entity.client)) {
                this.entity.clientId = this.entity.client.id;
                this.entity.client = null;
            }

            this.entity.postingCategoryId = null;
            if (!ObjectUtil.isNull(this.entity.postingCategory)) {
                this.entity.postingCategoryId = this.entity.postingCategory.id;
                this.entity.postingCategory = null;
            }

            if ((this.checkReceivedType === 'NEW') || this.checkReceivedType === 'DIFFERENCE') {
                if (ArrayUtil.isEmpty(this.data.transactionConciliation.checkReceivedListInternal)) {
                    this.data.transactionConciliation.checkReceivedListInternal = [];
                }
                this.entity.orderId = this.data.transactionConciliation.checkReceivedListInternal.length + 1;
                this.data.transactionConciliation.checkReceivedListInternal.push(this.entity);
            } else if (this.checkReceivedType === 'UPDATE') {
                const indexCheckReceived: number = this.data.transactionConciliation.checkReceivedListInternal
                    .findIndex(e => (e.id === this.entity.id) || (e.orderId === this.entity.orderId) || (e === this.entity));

                if (indexCheckReceived >= 0) {
                    this.data.transactionConciliation.checkReceivedListInternal.splice(indexCheckReceived, 1, this.entity);
                }
            }

            this.close('RELOAD_TABLE');
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
