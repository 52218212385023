export enum SaleScanntechAuditStatusEnum {
    ERROR = 'Erro',
    PENDING = 'Pendente',
    SENT = 'Enviado',
}

export type SaleScanntechAuditStatus = (
    | 'ERROR'
    | 'PENDING'
    | 'SENT'
);
