export enum SaleScanntechAuditOriginEnum {
    DAILY_CLOSING_RESEND = 'Reenvio do fechamento diário',
    DAILY_CLOSING_SHIPPING = 'Envio do fechamento diário',
    PROMOTIONS_CONSULTATION = 'Consulta de promoções',
    RESEND_REQUEST_CONSULTATION = 'Consulta do pedido de reenvio',
    SALES_RESEND = 'Reenvio de vendas',
    SALES_SHIPPING = 'Envio de vendas',
}

export type SaleScanntechAuditOrigin = (
    | 'DAILY_CLOSING_RESEND'
    | 'DAILY_CLOSING_SHIPPING'
    | 'PROMOTIONS_CONSULTATION'
    | 'RESEND_REQUEST_CONSULTATION'
    | 'SALES_RESEND'
    | 'SALES_SHIPPING'
);
