import { registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ShepherdService } from '@gipi-shepherd/services/shepherd.service';

import { AppMessages, CoreModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter, SharedModule } from '@gipisistemas/ng-core';
import { AppRoutingModule } from './app-routing.module';
import { OauthModule } from './oauth/oauth.module';
import { PersonModule } from './registration/person/person.module';

import { getPortuguesePaginatorIntl } from './portuguese-paginator-intl';

import { AppComponent } from './app.component';

import { ReplicateConfirmationDialogComponent } from '@gipi-financial/access-control/components/replicate-confirmation-dialog/replicate-confirmation-dialog.component';
import { BankAccountsTransferDialogComponent } from '@gipi-financial/bank-account-transfer/components/bank-accounts-transfer-dialog/bank-accounts-transfer-dialog.component';
import { AddBillInstallmentDialogComponent } from '@gipi-financial/bill-conciliation/components/add-bill-installment-dialog/add-bill-installment-dialog.component';
import { AddCheckReceivedDialogComponent } from '@gipi-financial/bill-conciliation/components/add-check-received-dialog/add-check-received-dialog.component';
import { FindReceivementCardComponent } from '@gipi-financial/bill-conciliation/components/find-receivement-card-administrator/find-receivement-card-administrator.component';
import { FindSaleDialogComponent } from '@gipi-financial/bill-conciliation/components/find-sale-dialog/find-sale-dialog.component';
import { ImportExtractDialogComponent } from '@gipi-financial/bill-conciliation/components/import-extract-dialog/import-extract-dialog.component';
import { AuthorizationDialogComponent } from '@gipi-financial/bill/components/authorization-dialog/authorization-dialog.component';
import { DeleteInstallmentDialogComponent } from '@gipi-financial/bill/components/delete-installment-dialog/delete-installment-dialog.component';
import { FindBillDialogComponent } from '@gipi-financial/bill/components/find-bill-dialog/find-bill-dialog.component';
import { InstallmentDialogComponent } from '@gipi-financial/bill/components/installment-dialog/installment-dialog.component';
import { PaymentVoucherComponent } from '@gipi-financial/bill/components/payment-voucher/payment-voucher.component';
import { RecurringReleaseDialogComponent } from '@gipi-financial/bill/components/recurring-release-dialog/recurring-release-dialog.component';
import { ActionsCheckReceivedDialogComponent } from '@gipi-financial/check-received/components/actions-check-received-dialog/actions-check-received-dialog.component';
import { ListCheckReceivedDialogComponent } from '@gipi-financial/check-received/components/list-check-received-dialog/list-check-received-dialog.component';
import { RefundDialogComponent } from '@gipi-financial/check-received/components/refund-dialog/refund-dialog.component';
import { ConfigurationDialogComponent } from '@gipi-financial/configuration/components/configuration-dialog/configuration-dialog.component';
import { CancelPaymentDialogComponent } from '@gipi-financial/payment/components/cancel-payment-dialog/cancel-payment-dialog.component';
import { PaymentDialogComponent } from '@gipi-financial/payment/components/payment-dialog/payment-dialog.component';
import { ReceivementDialogComponent } from '@gipi-financial/receivement/components/receipt-dialog/receivement-dialog.component';
import { ReceivementCardDialogComponent } from '@gipi-financial/receivement/components/receivement-card-dialog/receivement-card-dialog.component';
import { ReceivementCheckDialogComponent } from '@gipi-financial/receivement/components/receivement-check-dialog/receivement-check-dialog.component';
import { ReceivementDepositDialogComponent } from '@gipi-financial/receivement/components/receivement-deposit-dialog/receivement-deposit-dialog.component';
import { ApplicationFormDialogComponent } from '@gipi-registration/application/components/application-form-dialog/application-form-dialog.component';
import { ApplicationListDialogComponent } from '@gipi-registration/application/components/application-list-dialog/application-list-dialog.component';
import { BenefitClubFormDialogComponent } from '@gipi-registration/benefit-club/components/benefit-club-form-dialog/benefit-club-form-dialog.component';
import { CardAdministratorFormDialogComponent } from '@gipi-registration/card-administrator/components/card-administrator-form-dialog/card-administrator-form-dialog.component';
import { ClientClassificationFormDialogComponent } from '@gipi-registration/client-classification/components/client-classification-form-dialog/client-classification-form-dialog.component';
import { ClientDialogComponent } from '@gipi-registration/client/components/client-dialog/client-dialog.component';
import { FindClientDialogComponent } from '@gipi-registration/client/components/find-client-dialog/find-client-dialog.component';
import { ConnectConsultDialogComponent } from '@gipi-registration/connect-consult/components/connect-consult-dialog/connect-consult-dialog.component';
import { GIPIClientSheetCnpjDialogComponent } from '@gipi-registration/consult-cnpj/components/client-sheet-cnpj-dialog/client-sheet-cnpj-dialog.component';
import { DepartmentFormDialogComponent } from '@gipi-registration/department/components/department-form-dialog/department-form-dialog.component';
import { EmployeeRolesFormDialogComponent } from '@gipi-registration/employee-role/components/employee-roles-form-dialog/employee-roles-form-dialog.component';
import { IntermediaryFormDialogComponent } from '@gipi-registration/intermediary/components/intermediary-form-dialog/intermediary-form-dialog.component';
import { ManufacturerFormDialogComponent } from '@gipi-registration/manufacturer/components/manufacturer-form-dialog/manufacturer-form-dialog.component';
import { PersonListDialogComponent } from '@gipi-registration/person/components/person-list-dialog/person-list-dialog.component';
import { ProductGroupingsFormDialogComponent } from '@gipi-registration/product-grouping/components/product-groupings-form-dialog/product-groupings-form-dialog.component';
import { RegistrationProductListDialogComponent } from '@gipi-registration/product/components/product-list-dialog/product-list-dialog.component';
import { RepresentativeProviderDialogComponent } from '@gipi-registration/provider/components/representative-provider-dialog/representative-provider-dialog.component';
import { TransporterFormDialogComponent } from '@gipi-registration/transporter/components/transporter-form-dialog/transporter-form-dialog.component';
import { UnitMeasurementFormDialogComponent } from '@gipi-registration/unit-measurement/components/unit-measurement-form-dialog/unit-measurement-form-dialog.component';
import { ClientSummaryDialogComponent } from '@gipi-report/client/components/client-summary-dialog/client-summary-dialog.component';
import { ClientFormDialogComponent } from '@gipi-sale/client/components/client-form-dialog/client-form-dialog.component';
import { PaymentMethodsFormDialogComponent } from '@gipi-sale/payment-method/components/payment-methods-form-dialog/payment-methods-form-dialog.component';
import { SaleProductListDialogComponent } from '@gipi-sale/product/components/product-list-dialog/product-list-dialog.component';
import { ReasonDevolutionFormDialogComponent } from '@gipi-sale/reason-devolution/components/reason-devolution-form-dialog/reason-devolution-form-dialog.component';
import { SaleDataDialogComponent } from '@gipi-sale/sale/components/sale-data-dialog/sale-data-dialog.component';
import { SaleListDialogComponent } from '@gipi-sale/sale/components/sale-list-dialog/sale-list-dialog.component';
import { GIPINoveltiesDialogComponent } from '@gipi-shared/components/novelties-dialog/novelties-dialog.component';
import { UserInformationDialogComponent } from '@gipi-shared/components/user-information-dialog/user-information-dialog.component';
import { WhatsappContactDialogComponent } from '@gipi-shared/components/whatsapp-contact-dialog/whatsapp-contact-dialog.component';
import { IntegrationConnectDialogComponent } from './integration/components/integration-connect-dialog/integration-connect-dialog.component';
import { IntegrationScanntechAuditDetailsDialogComponent } from './integration/components/integration-scanntech-audit-details-dialog/integration-scanntech-audit-details-dialog.component';
import { IntegrationScanntechDialogComponent } from './integration/components/integration-scanntech-dialog/integration-scanntech-dialog.component';
import { IntegrationWhatsappDialogComponent } from './integration/components/integration-whatsapp-dialog/integration-whatsapp-dialog.component';
import { GenerateBillReceivableDialogComponent } from './notification/invoice-integrations/components/generate-bill-receivable-dialog/generate-bill-receivable-dialog.component';
import { PersonAssociationDialogComponent } from './registration/person/components/person-association-dialog/person-association-dialog.component';

registerLocaleData(localePt, localePtExtra);

export const GIPI_MESSAGES: AppMessages = {
    SUCCESS: 'Operação realizada com sucesso',
    RETURN_CONFIRMATION: 'Deseja retornar para à tela de consulta?',
    DELETE_CONFIRMATION: 'Deseja realmente excluir este registro?',
    DELETE_ALL_CONFIRMATION: 'Deseja realmente excluir os registros selecionados?',
    BUTTON_OK_CONFIRMATION: 'OK',
    BUTTON_CANCEL_CONFIRMATION: 'Cancelar',
    NOT_PERMISSION: 'Infelizmente, esta ação não é permitida para o seu perfil',
};

@NgModule({
    declarations: [
        AppComponent,
        AuthorizationDialogComponent,
        ClientDialogComponent,
        InstallmentDialogComponent,
        RecurringReleaseDialogComponent,
        CancelPaymentDialogComponent,
        ReplicateConfirmationDialogComponent,
        UserInformationDialogComponent,
        PaymentDialogComponent,
        ReceivementDialogComponent,
        ListCheckReceivedDialogComponent,
        ActionsCheckReceivedDialogComponent,
        RefundDialogComponent,
        ReceivementDepositDialogComponent,
        SaleDataDialogComponent,
        ReceivementCardDialogComponent,
        ReceivementCheckDialogComponent,
        SaleListDialogComponent,
        DeleteInstallmentDialogComponent,
        BankAccountsTransferDialogComponent,
        ImportExtractDialogComponent,
        RepresentativeProviderDialogComponent,
        PaymentVoucherComponent,
        FindClientDialogComponent,
        ConfigurationDialogComponent,
        GIPINoveltiesDialogComponent,
        GIPIClientSheetCnpjDialogComponent,
        SaleProductListDialogComponent,
        RegistrationProductListDialogComponent,
        BenefitClubFormDialogComponent,
        ProductGroupingsFormDialogComponent,
        UnitMeasurementFormDialogComponent,
        ManufacturerFormDialogComponent,
        DepartmentFormDialogComponent,
        EmployeeRolesFormDialogComponent,
        PaymentMethodsFormDialogComponent,
        ClientClassificationFormDialogComponent,
        ApplicationFormDialogComponent,
        ApplicationListDialogComponent,
        PersonListDialogComponent,
        PersonAssociationDialogComponent,
        ClientFormDialogComponent,
        ReasonDevolutionFormDialogComponent,
        CardAdministratorFormDialogComponent,
        IntermediaryFormDialogComponent,
        TransporterFormDialogComponent,
        AddBillInstallmentDialogComponent,
        AddCheckReceivedDialogComponent,
        FindSaleDialogComponent,
        FindBillDialogComponent,
        WhatsappContactDialogComponent,
        ClientSummaryDialogComponent,
        GenerateBillReceivableDialogComponent,
        ConnectConsultDialogComponent,
        FindReceivementCardComponent,
        IntegrationConnectDialogComponent,
        IntegrationScanntechDialogComponent,
        IntegrationWhatsappDialogComponent,
        IntegrationScanntechAuditDetailsDialogComponent,
    ],
    entryComponents: [
        AuthorizationDialogComponent,
        ClientDialogComponent,
        InstallmentDialogComponent,
        RecurringReleaseDialogComponent,
        CancelPaymentDialogComponent,
        ReplicateConfirmationDialogComponent,
        UserInformationDialogComponent,
        PaymentDialogComponent,
        ReceivementDialogComponent,
        ListCheckReceivedDialogComponent,
        ActionsCheckReceivedDialogComponent,
        RefundDialogComponent,
        ReceivementDepositDialogComponent,
        SaleDataDialogComponent,
        ReceivementCardDialogComponent,
        ReceivementCheckDialogComponent,
        SaleListDialogComponent,
        DeleteInstallmentDialogComponent,
        BankAccountsTransferDialogComponent,
        ImportExtractDialogComponent,
        RepresentativeProviderDialogComponent,
        PaymentVoucherComponent,
        FindClientDialogComponent,
        ConfigurationDialogComponent,
        GIPINoveltiesDialogComponent,
        GIPIClientSheetCnpjDialogComponent,
        SaleProductListDialogComponent,
        RegistrationProductListDialogComponent,
        BenefitClubFormDialogComponent,
        ProductGroupingsFormDialogComponent,
        UnitMeasurementFormDialogComponent,
        ManufacturerFormDialogComponent,
        DepartmentFormDialogComponent,
        EmployeeRolesFormDialogComponent,
        PaymentMethodsFormDialogComponent,
        ClientClassificationFormDialogComponent,
        ApplicationFormDialogComponent,
        ApplicationListDialogComponent,
        PersonListDialogComponent,
        PersonAssociationDialogComponent,
        ClientFormDialogComponent,
        ReasonDevolutionFormDialogComponent,
        CardAdministratorFormDialogComponent,
        IntermediaryFormDialogComponent,
        TransporterFormDialogComponent,
        AddBillInstallmentDialogComponent,
        AddCheckReceivedDialogComponent,
        FindSaleDialogComponent,
        FindBillDialogComponent,
        WhatsappContactDialogComponent,
        ClientSummaryDialogComponent,
        GenerateBillReceivableDialogComponent,
        ConnectConsultDialogComponent,
        FindReceivementCardComponent,
        IntegrationConnectDialogComponent,
        IntegrationScanntechDialogComponent,
        IntegrationWhatsappDialogComponent,
        IntegrationScanntechAuditDetailsDialogComponent,
    ],
    imports: [
        CoreModule.setMessages(GIPI_MESSAGES),
        SharedModule,
        OauthModule,
        AppRoutingModule,
        MatDividerModule,
        MatInputModule,
        MatCardModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        HttpClientJsonpModule,
        PersonModule,
        // BrowserModule,
        BrowserAnimationsModule,
    ],
    providers: [
        ShepherdService,
        {
            provide: LOCALE_ID,
            useValue: 'pt'
        },
        {
            provide: MatPaginatorIntl,
            useValue: getPortuguesePaginatorIntl()
        },
        {
            provide: DateAdapter,
            useClass: NativeDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR',
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL', 'DD/MM/YYYY'],
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
