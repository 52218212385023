import { catchError, map, takeUntil } from 'rxjs/operators';
import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class SaleScanntechAuditService extends GIPIAbstractService {
    constructor(baseService) {
        super(SalePaths.scanntechAudit, 'v1');
        this.baseService = baseService;
    }
    findAll(filter, version) {
        return this.baseService.httpClient.post(this.url(`find-all`, version), filter, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findDetails(filter, version) {
        return this.baseService.httpClient.post(this.url(`find-details`, version), filter, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
SaleScanntechAuditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SaleScanntechAuditService_Factory() { return new SaleScanntechAuditService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: SaleScanntechAuditService, providedIn: "root" });
