import { HttpClient } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialBankAccountService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.bankAccount, http, authenticationService);
    }
    findByValue(value, page, size, sort, type) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            lSort = `&sort=${sort.property},${sort.direction}`;
        }
        let lType = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }
        return this.http.get(this.url(`find-by-value?page=${page}&size=${size}${lSort}${lType}&value=${lValue}`), this.options()).pipe(map(this.mapper), takeUntil(this.onDestroy), catchError(this.handleError));
    }
    validate(entity) {
        super.validate(entity);
        switch (entity.type.toString()) {
            case 'CHECKING_ACCOUNT':
                this.validateCheckingAccount(entity);
                break;
            case 'CREDIT_CARD':
                this.validateCreditCard(entity);
                break;
            case 'SAVINGS':
                this.validateSavings(entity);
                break;
            case 'INVESTMENT':
                this.validateInvestment(entity);
                break;
            case 'AUTOMATIC_APPLICATION':
                this.validateAutomaticApplication(entity);
                break;
            case 'OTHER':
                this.validateOthers(entity);
                break;
        }
    }
    validateCheckingAccount(entity) {
        if (ObjectUtil.isNewModel(entity.bank)
            || StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.modality)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.bank.code !== '999') {
            if (ObjectUtil.isNull(entity.agency)
                || ObjectUtil.isNull(entity.account)) {
                throw new Error('Campos obrigatórios (*) não informados');
            }
        }
    }
    validateCreditCard(entity) {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNewModel(entity.bankAccount)
            || StringUtil.isEmpty(entity.lastFourNumbers)
            || !NumberUtil.isPositive(entity.closingDay)
            || !NumberUtil.isPositive(entity.expirationDay)
            || ObjectUtil.isNull(entity.flagCard)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.closingDay > 31) {
            throw new Error('O Fechamento da Fatura não deve ser maior que 31');
        }
        if (entity.expirationDay > 31) {
            throw new Error('O Vencimento do Cartão não deve ser maior que 31');
        }
    }
    validateSavings(entity) {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.modality)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    validateInvestment(entity) {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    validateAutomaticApplication(entity) {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    validateOthers(entity) {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }
    existConciliation(id) {
        return this.http.get(this.url(`exist-conciliation/${id}`), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    readOfxFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.url('read-ofx-file'), formData).pipe(map(this.mapper), catchError(this.handleError));
    }
    importTransactions(id, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', String(id));
        return this.http.post(this.url('import-ofx-transactions'), formData).pipe(map(this.mapper), catchError(this.handleError));
    }
    existMovements(bankAccountId) {
        return this.http.get(this.url(`exist-movements/${String(bankAccountId)}`), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    dashboardSummary(page, size) {
        const validatedPage = Math.max(page, 0);
        const validatedSize = Math.max(size, 10);
        const params = new URLSearchParams();
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('sort', 'description,ASC');
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.http.get(this.url(`dashboard-summary${queryString}`), this.options()).pipe(map(this.mapper), takeUntil(this.onDestroy), catchError(this.handleError));
    }
}
FinancialBankAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBankAccountService_Factory() { return new FinancialBankAccountService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialBankAccountService, providedIn: "root" });
