import { ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { FinancialBankAccountSelectDTO } from '@gipi-financial/bank-account/models/dto/bank-account-select.dto';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheckReceivedFilterDTO } from '@gipi-financial/check-received/models/dto/check-received-filter.dto';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { FinancialCheckStatus } from '@gipi-financial/check/enums/check-status.enum';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { AppliedFilter } from '@gipi-shared/applied-filter.dto';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export type FilterTypes = 'begin_and_end_date' | 'begin_date' | 'end_date' | 'posting_category' | 'client' | 'bank_account' | 'issuer' | 'check_number' | 'amount';

export interface ListCheckReceivedData {
    checkReceivedIdList: GIPIUuid[];
    onlyStatus: FinancialCheckStatus[];
}

@Component({
    templateUrl: './list-check-received-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ListCheckReceivedDialogComponent extends GIPIAbstractComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;
    @ViewChild('checkAllTemplate', { static: true }) checkAllTemplate: TemplateRef<any>;

    filter: FinancialCheckReceivedFilterDTO = new FinancialCheckReceivedFilterDTO();

    checkReceivedPage: PageDTO<FinancialCheckReceived> = new PageDTO<FinancialCheckReceived>();

    selected: boolean = false;
    indeterminate: boolean = false;

    columnsTable: TableColumnDTO[] = [];

    appliedFilters: AppliedFilter<FilterTypes>[] = [];

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: FinancialConfiguration = this._sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }
        const result: PageDTO<FinancialClientSelectDTO> = await this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    clientFn = (obj: FinancialClientSelectDTO) => this._clientService.getDescription(obj);

    bankAccountFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBankAccountSelectDTO> = await this._bankAccountService.findByValue(value, page, 10).toPromise();
        return result;
    };

    postingCategoryFindByOperationFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    constructor(
        public dialogRef: MatDialogRef<ListCheckReceivedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ListCheckReceivedData = { checkReceivedIdList: [], onlyStatus: ['OPENED'] },
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _service: FinancialCheckReceivedService,
        private _clientService: FinancialClientService,
        private _bankAccountService: FinancialBankAccountService,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _sessionStorageService: SessionStorageService<any>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        this.columnsTable = this.createTableColumnsCheckReceived();
        super.ngOnInit();
        // this.find();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._changeDetectorRef.detach();
    }

    clear(): void {
        this.filter = new FinancialCheckReceivedFilterDTO();
        this.clearAppliedFilters();
        this.find(null);
    }

    clearAppliedFilters(): void {
        this.appliedFilters = [];
    }

    private _setAppliedFilters(): void {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();
        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description: string = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'begin_and_end_date'));
        } else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description: string = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description: string = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'end_date'));
            }
        }
        if (!StringUtil.isEmpty(this.filter.issuer)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Emitente', 'issuer'));
        }
        if (this.filter.checkNumber) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Número do cheque', 'check_number'));
        }
        if (this.filter.amount) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Valor', 'amount'));
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Cliente', 'client'));
        }
        if (!ObjectUtil.isNull(this.filter.bankAccount)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Conta bancária', 'bank_account'));
        }
        if (!ObjectUtil.isNull(this.filter.category)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Categoria', 'posting_category'));
        }
    }

    removeAppliedFilter(filter: { chip: AppliedFilter<FilterTypes>, index: number }): void {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'issuer': {
                this.filter.issuer = '';
                break;
            }
            case 'check_number': {
                this.filter.checkNumber = null;
                break;
            }
            case 'amount': {
                this.filter.amount = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                break;
            }
            case 'bank_account': {
                this.filter.bankAccount = null;
                break;
            }
            case 'posting_category': {
                this.filter.category = null;
                break;
            }
        }

        this.find(null);
    }

    public find(pageEvent?: any): void {
        try {
            if (DateUtil.isGreaterThan(this.filter.begin, this.filter.end)) {
                throw new Error('Data final não pode ser menor que data inicial');
            }

            this.loading = true;

            this.filter.statusList = ArrayUtil.clone(this.data.onlyStatus);
            this.checkReceivedPage = new PageDTO<FinancialCheckReceived>();

            this.filter.checkReceivedIdsExcluded = [];
            if (!ObjectUtil.isNull(this.data)) {
                this.filter.checkReceivedIdsExcluded = this.data.checkReceivedIdList;
            }

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
            }
            this.filter.offset = 0;

            this.selected = false;
            this.indeterminate = false;

            this._service.findAll(this.filter).toPromise().then((page: GIPIPageModel<FinancialCheckReceived>) => {
                this.checkReceivedPage = page;
                this._setAppliedFilters();
                this.loading = false;
            }, error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    createTableColumnsCheckReceived(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => DateUtil.format(obj.check.dueDate, DateUtil.DATE_FORMAT))
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(7)
                .build(),
            TableColumnBuilder.instance()
                .property('checkNumber')
                .name('Nº cheque')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => obj.check.checkNumber)
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(7)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('category.description')
                .name('Categoria')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => obj.check.category ? obj.check.category.description : '')
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .sliceLength(30)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('issuer')
                .name('Emitente')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => obj.issuer)
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .build(),
            TableColumnBuilder.instance()
                .property('bankAccount.description')
                .name('Conta bancária')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => obj.bankAccount ? obj.bankAccount.description : '')
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .sortable(true)
                .value((obj: FinancialCheckReceived) => CurrencyUtil.transform(obj.check.amount, '1.2-2'))
                .action((obj: FinancialCheckReceived) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(10)
                .build(),
        ];
    }

    public close(confirmation: boolean): void {
        if (confirmation) {
            this.dialogRef.close(this.checkReceivedPage.content.filter(e => e.selected));
        } else {
            this.dialogRef.close([]);
        }
    }

    public validateAllSelected(): void {
        const list: FinancialCheckReceived[] = this.checkReceivedPage.content.filter(checkReceived => checkReceived.selected);
        this.selected = list.length > 0;
        this.indeterminate = list.length > 0 && (list.length !== this.checkReceivedPage.content.length);
    }

    public checkAll(): void {
        this.checkReceivedPage.content.forEach(checkReceived => {
            checkReceived.selected = this.selected;
        });
        this.validateAllSelected();
        this._changeDetectorRef.detectChanges();
    }

}
