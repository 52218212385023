import { catchError, map, takeUntil } from 'rxjs/operators';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialClientService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.client, 'v1', baseService);
        this.baseService = baseService;
    }
    getDescription(entity) {
        if (ObjectUtil.isNull(entity)) {
            return '';
        }
        const isDTO = !('person' in entity);
        let name = isDTO ? entity.name : entity.person.name;
        const configuration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            const fantasyName = isDTO ? entity.fantasyName : entity.person.fantasyName;
            name = configuration.showBusinessNameInReceiptQuery
                ? (!StringUtil.isEmpty(fantasyName) ? fantasyName : name)
                : name;
        }
        const typePerson = isDTO
            ? entity.type
            : (!ObjectUtil.isNull(entity.person.legalPerson) ? 'LEGAL_PERSON' : 'NATURAL_PERSON');
        const cpfOrCnpj = (isDTO
            ? entity.cpfOrCnpj
            : ((typePerson === 'LEGAL_PERSON') ? entity.person.legalPerson.cnpj : entity.person.naturalPerson.cpf));
        const formatCpfOrCnpj = (typePerson === 'LEGAL_PERSON') ? '00.000.000/0000-00' : '000.000.000-00';
        const cpfOrCnpjFormatted = !StringUtil.isEmpty(cpfOrCnpj) ? StringUtil.format(cpfOrCnpj, formatCpfOrCnpj) + ' - ' : '';
        return `${cpfOrCnpjFormatted}${name}`;
    }
    findByCpfOrCnpj(cpfOrCnpj) {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=1`), this.options()).pipe(map((json) => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    getCard(id) {
        return this.baseService.httpClient.get(this.url(`card/${id}`), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByValue(value, page, size, sort, version) {
        let lValue = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        }
        else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }
        return this.baseService.httpClient.get(this.url(`find-by-value?migrated=true&page=${page}&size=${size}${lSort}&value=${lValue}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    clientSale(id, page, size, sort, version) {
        if (!UUIDUtil.isValid(id)) {
            return;
        }
        const validatedPage = Math.max(page, 0);
        const validatedSize = Math.max(size, 10);
        const params = new URLSearchParams();
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            params.set('sort', `${sort.field},${sort.direction}`);
        }
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.baseService.httpClient.get(this.url(`client-sale/${id}${queryString}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialClientService_Factory() { return new FinancialClientService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialClientService, providedIn: "root" });
