import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { ConfigurationPaths } from '@gipi-paths/configuration.paths';
import { GIPIAbstractService, GIPIBaseService } from '@gipisistemas/ng-core';
import { ConfigurationScanntech } from '../models/scanntech.model';

@Injectable({ providedIn: 'root' })
export class ConfigurationConfigurationService extends GIPIAbstractService {

    /** Se trocar as permissões enquanto estiver na tela que usa a scanntech */
    public updated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(protected baseService: GIPIBaseService) {
        super(ConfigurationPaths.configuration, 'v1');
    }

    findScanntech(version?: string): Observable<ConfigurationScanntech> {
        return this.baseService.httpClient.get(this.url(`scanntech`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(_ => of(null)),
        );
    }

    saveScanntech(entity: ConfigurationScanntech, version?: string): Observable<ConfigurationScanntech> {
        return this.baseService.httpClient.post(this.url(`scanntech`, version), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
