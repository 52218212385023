import { NotificationCustomMessagingConfiguration } from '@gipi-notification/custom-messaging-configuration/models/custom-messaging-configuration.model';
import { NotificationNotificationSubType } from '@gipi-notification/invoice-integrations/enums/notification-subtype.enum';
import { NotificationNotificationType } from '@gipi-notification/invoice-integrations/enums/notification-type.enum';
import { ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export type WppFormatTypes = '*' | '_' | '~' | '```';

export type WppParamTypes = '${company}' | '${name}' | '${documentNumber}' | '${amount}' | '${paymentKey}' | '${issuanceDate}' | '${dueDate}' | '${description}' | '${startDate}' | '${endDate}' | '${contact}';

/**
 * Permite adicionar chaves extras que não estão definidas em WppParamTypes.
 * Dessa forma, não é necessário incluir essas chaves no WppParamTypes,
 * já que as chaves nele definidas serão exibidas automaticamente na tela de configurações de mensagens do WhatsApp.
 */
export type WppParams = { [key in WppParamTypes]?: string; } & { [key: string]: string; };

export type WppNotificationTypes = (
    NotificationNotificationType |
    NotificationNotificationSubType |
    'CLIENT_WITH_PURCHASES_IN_THE_LAST_THIRTY_DAYS' |
    'CLIENT_WITH_PURCHASES_MORE_THAN_THIRTY_DAYS' |
    'CLIENT_WITH_LATE_BILLS_IN_REPORT'
);

export interface WppOptionMessage {
    key: WppNotificationTypes;
    params: WppParamTypes[];
    title: string;
    description: string;
    enabled: boolean;
    selected: boolean;
    oldMessage: string;
    message: string;
    defaultMessage: string;
    objectApi?: NotificationCustomMessagingConfiguration,
}

export const accountPayable: WppOptionMessage = {
    key: 'ACCOUNT_PAYABLE',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${issuanceDate}', '${dueDate}', '${contact}',],
    title: 'Contas a pagar',
    description: 'Mensagem para contas a pagar',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: '',
    objectApi: null,
};

export const accountReceivable: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${issuanceDate}', '${dueDate}', '${contact}',],
    title: 'Contas a receber',
    description: 'Mensagem para contas a receber',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: '',
    objectApi: null,
};

export const birthday: WppOptionMessage = {
    key: 'BIRTHDAY',
    params: ['${company}', '${name}', '${contact}',],
    title: 'Aniversários',
    description: 'Parabenizar aniversariantes',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Gostaríamos de parabenizá-lo(a) pelo seu aniversário! Que seu dia seja tão especial quanto você é para nós. Venha nos visitar e se presentear nessa data tão significativa.\nFeliz aniversário!',
    objectApi: null,
};

export const potentialClient: WppOptionMessage = {
    key: 'POTENTIAL_CLIENT',
    params: ['${company}', '${name}', '${contact}',],
    title: 'Clientes potenciais',
    description: 'Clientes que nunca compraram',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Faz tempo que não vemos você por aqui! Gostaríamos de lembrá-lo(a) que temos muitas ofertas e novidades.\nSe precisar de algo ou tiver alguma dúvida, estamos à disposição para ajudar!',
    objectApi: null,
};

export const billetKey: WppOptionMessage = {
    key: 'BILLET_KEY',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${issuanceDate}', '${dueDate}', '${contact}',],
    title: 'Boletos bancários',
    description: 'Enviar chave de pagamento',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Notamos que o boleto no valor de *${amount}*, com vencimento em *${dueDate}*, ainda não foi pago. Para realizar o pagamento, utilize o código a seguir: *${paymentKey}*.\n\nCaso o pagamento já tenha sido efetuado, por favor, desconsidere esta mensagem.\n\nEstamos à disposição para qualquer dúvida.',
    objectApi: null,
};

export const promotionsAndSuggestions: WppOptionMessage = {
    key: 'PROMOTIONS_AND_SUGGESTIONS',
    params: ['${company}', '${name}', '${description}', '${startDate}', '${endDate}', '${contact}',],
    title: 'Promoções e sugestões',
    description: 'Mensagem sobre promoções',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Temos uma promoção por tempo limitado, válida apenas de *${startDate}* até *${endDate}*. Não perca as novidades!\n\nEstamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableReceivable: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_RECEIVABLE',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${dueDate}', '${contact}',],
    title: 'Contas a receber',
    description: 'Lembrete de contas a receber',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Gostaríamos de lembrar sobre o pagamento pendente da fatura número *${documentNumber}*, no valor de *${amount}*, com vencimento em *${dueDate}*. Por favor, verifique e efetue o pagamento quando puder. Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableReceived: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_RECEIVED',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${dueDate}', '${contact}',],
    title: 'Contas recebidas',
    description: 'Confirmar recebimento da conta',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Confirmamos o recebimento referente à fatura número *${documentNumber}*. Agradecemos pela pontualidade! Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableGrouped: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_GROUPED',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${dueDate}', '${contact}',],
    title: 'Contas agrupadas',
    description: 'Alertar sobre agrupamento de contas',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Agrupamos suas faturas em uma única cobrança e o vencimento está para *${dueDate}*. Agradecemos sua atenção! Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableRenegotiated: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_RENEGOTIATED',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${dueDate}', '${contact}',],
    title: 'Contas renegociadas',
    description: 'Alertar sobre contas renegociadas',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Sua fatura número *${documentNumber}* foi renegociada e a nova data de vencimento é *${dueDate}*. Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableOverdue: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_OVERDUE',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${dueDate}', '${contact}',],
    title: 'Contas atrasadas',
    description: 'Lembrete de contas atrasadas',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Gostaríamos de lembrar sobre o pagamento pendente da fatura número *${documentNumber}*, no valor de *${amount}*, vencida desde *${dueDate}*. Por favor, regularize o pagamento o quanto antes. Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const accountReceivableReceivedPartial: WppOptionMessage = {
    key: 'ACCOUNT_RECEIVABLE_RECEIVED_PARTIAL',
    params: ['${company}', '${name}', '${documentNumber}', '${amount}', '${paymentKey}', '${dueDate}', '${contact}',],
    title: 'Recebimento parcial',
    description: 'Confirmar recebimento parcial da conta',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Recebemos parte do seu pagamento referente à fatura número *${documentNumber}*. Gostaríamos de lembrá-lo que o restante vence em *${dueDate}*. Agradecemos sua atenção e pontualidade! Estamos à disposição para quaisquer dúvidas.',
    objectApi: null,
};

export const clientWithPurchasesInTheLastThirtyDays: WppOptionMessage = {
    key: 'CLIENT_WITH_PURCHASES_IN_THE_LAST_THIRTY_DAYS',
    params: ['${company}', '${name}', '${contact}',],
    title: 'Clientes recorrentes',
    description: 'Compraram nos últimos 30 dias',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Gostaríamos de agradecer por sua confiança em nossa empresa. Sabemos que você realizou uma compra conosco nos últimos 30 dias e esperamos que tenha ficado satisfeito(a) com seu pedido.\nCaso precise de algo mais, estamos à disposição para ajudar!',
    objectApi: null,
};

export const clientWithPurchasesMoreThanThirtyDays: WppOptionMessage = {
    key: 'CLIENT_WITH_PURCHASES_MORE_THAN_THIRTY_DAYS',
    params: ['${company}', '${name}', '${contact}',],
    title: 'Clientes regulares',
    description: 'Compraram há mais de 30 dias',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Notamos que sua última compra conosco foi há mais de 30 dias. Gostaríamos de lembrá-lo(a) de que estamos sempre atualizando nosso estoque com novos produtos e ofertas especiais. Seria um prazer vê-lo(a) de volta!\nPara mais informações, estaremos à sua disposição.',
    objectApi: null,
};

export const clientWithLateBillsInReport: WppOptionMessage = {
    key: 'CLIENT_WITH_LATE_BILLS_IN_REPORT',
    params: ['${company}', '${name}', '${contact}',],
    title: 'Clientes regulares',
    description: 'Compraram há mais de 30 dias',
    enabled: true,
    selected: false,
    oldMessage: '',
    message: '',
    defaultMessage: 'Identificamos que há uma pendência ${dueDate} no valor total de ${originalAmount}. Até o momento, foi pago ${dischargeAmount}, restando um saldo de ${amount} para quitação.\n\nEstamos à sua disposição para auxiliá-lo(a) na regularização.\n\nCaso o pagamento já tenha sido efetuado, pedimos que desconsidere esta mensagem.',
    objectApi: null,
};

/** As chaves definidas serão exibidas automaticamente na tela de configurações de mensagens do WhatsApp. */
export const wppOptionMessageList: WppOptionMessage[] = [
    // ObjectUtil.clone(accountReceivable), Não será utilizado por enquanto
    ObjectUtil.clone(accountReceivableReceivable),
    ObjectUtil.clone(accountReceivableOverdue),
    ObjectUtil.clone(accountReceivableReceived),
    ObjectUtil.clone(accountReceivableReceivedPartial),
    ObjectUtil.clone(accountReceivableGrouped),
    ObjectUtil.clone(accountReceivableRenegotiated),
    // ObjectUtil.clone(accountPayable), Não será utilizado por enquanto
    ObjectUtil.clone(birthday),
    ObjectUtil.clone(potentialClient),
    ObjectUtil.clone(billetKey),
    // ObjectUtil.clone(promotionsAndSuggestions), Não será utilizado por enquanto
    // ObjectUtil.clone(clientWithPurchasesInTheLastThirtyDays), Não foi adicionado a configuração ainda
    // ObjectUtil.clone(clientWithPurchasesMoreThanThirtyDays), Não foi adicionado a configuração ainda
];

export const wppDefaultMessages: { [key in WppNotificationTypes]: WppOptionMessage } = {
    'ACCOUNT_PAYABLE': accountPayable,
    'ACCOUNT_RECEIVABLE': accountReceivable,
    'BIRTHDAY': birthday,
    'POTENTIAL_CLIENT': potentialClient,
    'BILLET_KEY': billetKey,
    'PROMOTIONS_AND_SUGGESTIONS': promotionsAndSuggestions,
    'ACCOUNT_RECEIVABLE_RECEIVABLE': accountReceivableReceivable,
    'ACCOUNT_RECEIVABLE_RECEIVED': accountReceivableReceived,
    'ACCOUNT_RECEIVABLE_GROUPED': accountReceivableGrouped,
    'ACCOUNT_RECEIVABLE_RENEGOTIATED': accountReceivableRenegotiated,
    'ACCOUNT_RECEIVABLE_OVERDUE': accountReceivableOverdue,
    'ACCOUNT_RECEIVABLE_RECEIVED_PARTIAL': accountReceivableReceivedPartial,
    'CLIENT_WITH_PURCHASES_IN_THE_LAST_THIRTY_DAYS': clientWithPurchasesInTheLastThirtyDays,
    'CLIENT_WITH_PURCHASES_MORE_THAN_THIRTY_DAYS': clientWithPurchasesMoreThanThirtyDays,
    'CLIENT_WITH_LATE_BILLS_IN_REPORT': clientWithLateBillsInReport,
}

// export function wppTransformParamsToValues(params: WppParams, message: string, key: WppNotificationTypes, encode: boolean = false): string {
//     if (ObjectUtil.isNull(params) || StringUtil.isEmpty(message)) {
//         return null;
//     }

//     if (StringUtil.isEmpty(message)) {
//         const messageDefault: string = wppDefaultMessages[key].defaultMessage;
//         if (StringUtil.isEmpty(messageDefault)) {
//             return null;
//         }

//         return wppTransformParamsToValues(params, messageDefault, key);
//     }

//     const messageAux: string = message.replace(/\${(.*?)}/g, (match, tag) => {
//         const key: string = `\${${tag}}`;
//         return params[key] || match;
//     });

//     return (encode ? StringUtil.encodeBase64(messageAux) : messageAux);
// }

export class WppOptionsMessages {

    public static transformParamsToValues(params: WppParams, message: string, key: WppNotificationTypes, encode: boolean = false): string {
        if (ObjectUtil.isNull(params)) {
            return null;
        }

        if (StringUtil.isEmpty(message)) {
            const messageDefault: string = wppDefaultMessages[key].defaultMessage;
            if (StringUtil.isEmpty(messageDefault)) {
                return null;
            }

            return this.transformParamsToValues(params, messageDefault, key, encode);
        }

        const messageAux: string = message.replace(/\${(.*?)}/g, (match, tag) => {
            const key: string = `\${${tag}}`;
            return ((params[key] !== null) && (params[key] !== undefined)) ? params[key] : match;
        });

        return (encode ? StringUtil.encodeBase64(messageAux) : messageAux);
    }

}
