import * as tslib_1 from "tslib";
import { formatNumber } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ReceivementCardDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _changeDetectorRef, _cardAdministratorService, _flagCardService, _receivementService, dialogRef, data = null) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._changeDetectorRef = _changeDetectorRef;
        this._cardAdministratorService = _cardAdministratorService;
        this._flagCardService = _flagCardService;
        this._receivementService = _receivementService;
        this.dialogRef = dialogRef;
        this.data = data;
        this._receivement = null;
        this.receivementCardList = [];
        this.columns = [];
        this.receivementCard = this._newReceivementCard();
        this.quantityInstallment = 1;
        this.optionAmountCard = { allowNegative: false, min: 0, max: this.amountRemaining };
        this.cardAdministratorFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._cardAdministratorService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.flagCardFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._flagCardService.findByValue(value, page, 10).toPromise();
            return result;
        });
    }
    get amountRemaining$() {
        return of(this.amountRemaining);
    }
    get isValidAddReceivementCard$() {
        if (ObjectUtil.isNull(this.receivementCard)) {
            return of(false);
        }
        return of(!ObjectUtil.isNull(this.receivementCard.cardAdministrator) &&
            !ObjectUtil.isNull(this.receivementCard.flagCard) &&
            NumberUtil.isPositive(this.receivementCard.amountCard) &&
            (this.quantityInstallment >= 1));
    }
    get amountToReceive() {
        const receivementAux = !ObjectUtil.isNull(this._receivement) ? this._receivement : this.data.receivement;
        return (receivementAux.amountReceived ? receivementAux.amountReceived : receivementAux.amountReceivable);
    }
    get amountAdded() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return 0;
        }
        const amountAdded = this.receivementCardList.reduce((sum, e) => sum += e.amountCard, 0);
        return amountAdded;
    }
    get amountRemaining() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return this.amountToReceive;
        }
        return (this.amountToReceive - this.amountAdded);
    }
    ngOnInit() {
        super.ngOnInit();
        this.columns = this._createTableColumns();
        if (!ObjectUtil.isNull(this.data)) {
            this._receivement = ObjectUtil.clone(this.data.receivement);
            this._receivement.amountNet = this._receivement.amountReceivable;
        }
        this._changeDetectorRef.markForCheck();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    _createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj) => obj.flagCard.description)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor bruto')
                .value((obj) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('appliedFee')
                .name('Taxa (%)')
                .value((obj) => `${formatNumber(obj.appliedFee, 'pt-BR', '1.2-2')} %`)
                .align('center center')
                .width(6)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Valor líquido')
                .value((obj) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }
    _newReceivementCard() {
        const entity = new FinancialReceivementCard();
        entity.chargeType = ObjectUtil.clone(this.data.receivement.chargeType);
        entity.bankAccount = ObjectUtil.clone(this.data.receivement.bankAccount);
        entity.cardAdministrator = null;
        entity.flagCard = null;
        entity.issuanceDate = new Date();
        entity.dueDate = new Date();
        entity.status = 'RECEIVABLE';
        entity.amountCard = this.amountRemaining;
        entity.amountNet = 0;
        entity.appliedFee = 0;
        return entity;
    }
    _getOrderId() {
        const orderId = this.receivementCardList.map(e => e.orderId);
        if (orderId.length > 0) {
            return Math.max(...orderId) + 1;
        }
        return 1;
    }
    _validateAddReceivementCard() {
        if (ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            this.addWarningMessage('Campo administradora de cartão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.receivementCard.flagCard)) {
            this.addWarningMessage('Campo bandeira do cartão é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            this.addWarningMessage('Campo quantidade de parcelas é obrigatório e não foi informado');
            return false;
        }
        const amountAddedAux = this.amountAdded;
        const amountToReceiveAux = this.amountToReceive;
        if (amountAddedAux === amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${(amountAddedAux).toFixed(2)} já corresponde ao valor total do recebimento`);
            return false;
        }
        if ((this.receivementCard.amountCard + amountAddedAux) > amountToReceiveAux) {
            this.addWarningMessage(`O valor que você está tentando lançar R$ ${(amountAddedAux).toFixed(2)} ultprassa o valor total do recebimento`);
            return;
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            this.addWarningMessage('Campo data da emissão é obrigatório e não foi informado');
            return false;
        }
        if ((this.quantityInstallment > 1) && ((this.receivementCard.amountCard / this.quantityInstallment) < 1)) {
            this.addWarningMessage('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
            return false;
        }
        return true;
    }
    addReceivementCard() {
        try {
            if (!this._validateAddReceivementCard()) {
                return;
            }
            this.loading = true;
            const receivementCardListAux = [];
            const now = new Date();
            const orderId = this._getOrderId();
            const daysAdd = ((this.receivementCard.chargeType.type === 'DEBIT_CARD') ?
                this.receivementCard.cardAdministrator.dayDebitReceipt :
                this.receivementCard.cardAdministrator.dayCreditReceipt);
            // Taxa bruta
            const grossFee = ((this.receivementCard.chargeType.type === 'DEBIT_CARD') ?
                this.receivementCard.cardAdministrator.debitFee :
                ((this.quantityInstallment === 1) ? this.receivementCard.cardAdministrator.cashFee : this.receivementCard.cardAdministrator.installmentFee));
            // Taxa rateada nas parcelas
            const proratedFee = (grossFee / this.quantityInstallment);
            const amountCard = this.receivementCard.amountCard;
            const amountCardInstallment = (this.receivementCard.amountCard / this.quantityInstallment);
            const amountNet = (this.receivementCard.amountCard - (this.receivementCard.amountCard * (grossFee / 100)));
            const amountNetInstallment = (amountNet / this.quantityInstallment);
            for (let i = 0; i < this.quantityInstallment; i++) {
                const receivementCard = ObjectUtil.clone(this.receivementCard);
                receivementCard.amountCard = Number(amountCardInstallment.toFixed(2));
                receivementCard.amountNet = Number(amountNetInstallment.toFixed(2));
                receivementCard.appliedFee = Number(proratedFee.toFixed(2));
                receivementCard.dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCard.numberInstallment = i + 1;
                receivementCard.orderId = orderId;
                receivementCardListAux.push(receivementCard);
            }
            // Ajusta os valores, caso ultrapasse ou falte em relação ao valor total
            this._adjustAmounts(receivementCardListAux, amountCard, amountNet, grossFee);
            this.receivementCardList = [...this.receivementCardList, ...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;
            this.loading = false;
            this._changeDetectorRef.detectChanges();
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    _adjustAmounts(receivementCardList, amountCard, amountNet, grossFee) {
        // Refatora o amountCard
        const amountCardAdded = receivementCardList.reduce((sum, e) => sum + e.amountCard, 0);
        const amountCardAddedDiff = Number(amountCardAdded.toFixed(2)) - amountCard;
        if (amountCardAddedDiff > 0) {
            receivementCardList[0].amountCard -= amountCardAddedDiff;
        }
        else if (amountCardAddedDiff < 0) {
            receivementCardList[0].amountCard += (amountCardAddedDiff * -1);
        }
        // Refatora o amountNet
        const amountNetAdded = receivementCardList.reduce((sum, e) => sum + e.amountNet, 0);
        const amountNetAddedDiff = Number(amountNetAdded.toFixed(2)) - amountNet;
        if (amountNetAddedDiff > 0) {
            receivementCardList[0].amountNet -= amountNetAddedDiff;
        }
        else if (amountNetAddedDiff < 0) {
            receivementCardList[0].amountNet += (amountNetAddedDiff * -1);
        }
        // Refatora a appliedFee
        const appliedFeeAdded = receivementCardList.reduce((sum, e) => sum + e.appliedFee, 0);
        const appliedFeeAddedDiff = Number(appliedFeeAdded.toFixed(2)) - grossFee;
        if (appliedFeeAddedDiff > 0) {
            receivementCardList[0].appliedFee -= appliedFeeAddedDiff;
        }
        else if (appliedFeeAddedDiff < 0) {
            receivementCardList[0].appliedFee += (appliedFeeAddedDiff * -1);
        }
    }
    removeReceivementCard(entity) {
        try {
            this.loading = true;
            const receivementCardListAux = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard = receivementCardListAux.findIndex(e => (e.orderId === entity.orderId) && (e.numberInstallment === entity.numberInstallment));
            receivementCardListAux.splice(indexReceivementCard, 1);
            const receivementCardByOrderId = receivementCardListAux.filter(e => e.orderId === entity.orderId);
            if (!ArrayUtil.isEmpty(receivementCardByOrderId)) {
                const now = new Date();
                const daysAdd = ((entity.chargeType.type === 'CREDIT_CARD') ?
                    entity.cardAdministrator.dayCreditReceipt :
                    entity.cardAdministrator.dayDebitReceipt) || 0;
                // Taxa bruta
                const grossFee = ((entity.chargeType.type === 'DEBIT_CARD') ?
                    entity.cardAdministrator.debitFee :
                    ((receivementCardByOrderId.length === 1) ? entity.cardAdministrator.cashFee : entity.cardAdministrator.installmentFee)) || 0;
                // Taxa rateada nas parcelas
                const proratedFee = (grossFee / receivementCardByOrderId.length);
                const amountCard = receivementCardByOrderId.reduce((sum, e) => sum += e.amountCard, 0);
                const amountCardInstallment = (amountCard / receivementCardByOrderId.length);
                const amountNet = (amountCard - (amountCard * (grossFee / 100)));
                const amountNetInstallment = (amountNet / receivementCardByOrderId.length);
                for (let i = 0; i < receivementCardByOrderId.length; i++) {
                    receivementCardByOrderId[i].amountCard = Number(amountCardInstallment.toFixed(2));
                    receivementCardByOrderId[i].amountNet = Number(amountNetInstallment.toFixed(2));
                    receivementCardByOrderId[i].appliedFee = Number(proratedFee.toFixed(2));
                    receivementCardByOrderId[i].dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                    receivementCardByOrderId[i].numberInstallment = i + 1;
                }
                // Ajusta os valores, caso ultrapasse ou falte em relação ao valor total
                this._adjustAmounts(receivementCardByOrderId, amountCard, amountNet, grossFee);
            }
            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;
            this.loading = false;
            this._changeDetectorRef.detectChanges();
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    _validateConfirm() {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            this.addWarningMessage('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
            return false;
        }
        const amountAddedAux = Number(this.amountAdded.toFixed(2));
        const amountToReceiveAux = this.amountToReceive;
        if (amountAddedAux < amountToReceiveAux) {
            this.addWarningMessage(`Ainda falta lançar R$ ${CurrencyUtil.transform(this.amountRemaining, '1.2-2')} para corresponder o valor total do recebimento`);
            return false;
        }
        if (amountAddedAux > amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAddedAux).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
            return false;
        }
        return true;
    }
    confirm() {
        try {
            if (!this._validateConfirm()) {
                return;
            }
            this.loading = true;
            this._receivementService.receive(this._receivement, this.data.installmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }).catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    close(isSaved) {
        this.dialogRef.close(isSaved);
    }
}
