import { SaleScanntechAuditOrigin } from '@gipi-sale/scanntech-audit/enums/scanntech-audit-origin.enum';
import { SaleScanntechAuditStatus } from '@gipi-sale/scanntech-audit/enums/scanntech-audit-status.enum';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIUuid } from '@gipisistemas/core';

export class SaleScanntechDetailedAuditFilterDTO extends GIPIBaseAbstractFilterModel {

    dailyClosingId: GIPIUuid;

    originList: SaleScanntechAuditOrigin[];

    saleId: GIPIUuid;

    scanntechIntegrationAuditId: GIPIUuid;

    statusList: SaleScanntechAuditStatus[];

}
