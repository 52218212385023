import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { SalePaths } from '@gipi-paths/sale.paths';
import { GIPIAbstractService, GIPIBaseService, GIPIPageModel } from '@gipisistemas/ng-core';
import { SaleScanntechAuditConsultDTO } from '../models/dto/scanntech-audit-consult.dto';
import { SaleScanntechAuditFilterDTO } from '../models/dto/scanntech-audit-filter.dto';
import { SaleScanntechDetailedAuditConsultDTO } from '../models/dto/scanntech-detailed-audit-consult.dto';
import { SaleScanntechDetailedAuditFilterDTO } from '../models/dto/scanntech-detailed-audit-filter.dto';

@Injectable({ providedIn: 'root' })
export class SaleScanntechAuditService extends GIPIAbstractService {

    constructor(protected baseService: GIPIBaseService) {
        super(SalePaths.scanntechAudit, 'v1');
    }

    findAll(filter: SaleScanntechAuditFilterDTO, version?: string): Observable<GIPIPageModel<SaleScanntechAuditConsultDTO>> {
        return this.baseService.httpClient.post(this.url(`find-all`, version), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findDetails(filter: SaleScanntechDetailedAuditFilterDTO, version?: string): Observable<GIPIPageModel<SaleScanntechDetailedAuditConsultDTO>> {
        return this.baseService.httpClient.post(this.url(`find-details`, version), filter, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
