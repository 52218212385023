import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialSaleFilterDTO } from '@gipi-financial/sale/models/dto/sale-filter.dto';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationClientCardDTO } from '@gipi-registration/client/models/dto/client-card.dto';
import { SaleDataDialogComponent } from '@gipi-sale/sale/components/sale-data-dialog/sale-data-dialog.component';
import { WhatsappContactDialogComponent } from '@gipi-shared/components/whatsapp-contact-dialog/whatsapp-contact-dialog.component';
import { WhatsappContact } from '@gipi-shared/models/whatsapp-contact.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, DateUtil, DialogService, GIPIPageModel, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ClientDialogComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _clientService, _dialogService, data) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._clientService = _clientService;
        this._dialogService = _dialogService;
        this.data = data;
        this.columns = [];
    }
    ngOnInit() {
        super.ngOnInit();
        this.createTableColumns();
        this.page = new GIPIPageModel();
        this.client = this.data;
    }
    createTableColumns() {
        this.columns = [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .value((obj) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(12)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Documento')
                .value((obj) => !StringUtil.isEmpty(obj.documentNumber) ? obj.documentNumber : '')
                .align('center center')
                .width(12)
                .build(),
            TableColumnBuilder.instance()
                .property('chargeType')
                .name('Forma de pagamento')
                .value((obj) => !StringUtil.isEmpty(obj.paymentMethodName) ? obj.paymentMethodName : '')
                .build(),
            TableColumnBuilder.instance()
                .property('seller')
                .name('Vendedor')
                .value((obj) => !StringUtil.isEmpty(obj.sellerName) ? obj.sellerName : '')
                .build(),
            TableColumnBuilder.instance()
                .property('grossAmount')
                .name('Total bruto')
                .value((obj) => CurrencyUtil.transform(obj.grossAmount, '1.2-2'))
                .align('center center')
                .width(12)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Total Líquido')
                .value((obj) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .align('center center')
                .width(12)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(7)
                .align('center center')
                .template(this.actions)
                .build(),
        ];
    }
    // private _calculateSalePayment(entity: FinancialClientSaleDTO): number {
    //     const paymentList: FinancialSalePayment[] = [...entity.salePaymentList];
    //     if (ArrayUtil.isEmpty(paymentList)) {
    //         return 0;
    //     }
    //     const creditClientAmount: number = entity.creditClientList.filter(e => e.type === 'DEBIT').reduce((sum, e) => sum += e.amount, 0);
    //     const paymentAmount: number = paymentList.filter(e => e.chargeType.type !== 'CREDIT_CLIENT').reduce((sum, payment) => {
    //         if (
    //             (payment.chargeType.type === 'DISCOUNT') ||
    //             (payment.chargeType.type === 'CASH_CHANGE')
    //         ) {
    //             return sum -= payment.amountPaid;
    //         } else {
    //             return sum += payment.amountPaid;
    //         }
    //     }, 0);
    //     return Number((creditClientAmount + paymentAmount).toFixed());
    // }
    _newFilterDTO() {
        this._filter = new FinancialSaleFilterDTO();
        this._filter.client = new RegistrationClient();
        this._filter.client.id = this.client.id;
    }
    find(pageEvent) {
        try {
            this.loading = true;
            this.loadingChange.emit(this.loading);
            this.page = new PageDTO();
            if (ObjectUtil.isNull(this._filter)) {
                this._newFilterDTO();
            }
            this._filter.pageSize = 10;
            if (pageEvent) {
                this._filter.pageNumber = pageEvent.pageIndex;
                this._filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
            }
            else {
                this._filter.pageNumber = 0;
                this._filter.offset = 0;
            }
            this._clientService.clientSale(this._filter.client.id, this._filter.pageNumber, this._filter.pageSize).toPromise().then(page => {
                this.page = page;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    getColorByAmount(amount) {
        if (amount > 0) {
            return '#47ca82';
        }
        else if (amount < 0) {
            return '#ca0045';
        }
        else if (amount === 0) {
            return '#696969';
        }
    }
    navigateSaleData(entity) {
        this._dialogService.open({
            componentOrTemplateRef: SaleDataDialogComponent,
            data: entity.saleSaleId,
            width: '90%',
            height: '90%',
        });
    }
    whatsappContact() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!ObjectUtil.isNull(this.client)) {
                    const getRecipientPhone = (phoneList) => {
                        if (ArrayUtil.isEmpty(phoneList)) {
                            return '';
                        }
                        const phone = phoneList.find(e => e.type.type === 'WHATSAPP');
                        return !ObjectUtil.isNull(phone) ? phone.number : '';
                    };
                    const recipient = yield this._clientService.getOne(this.client.id).toPromise();
                    const recipientPhoneAux = getRecipientPhone(recipient.person.phoneList);
                    const keyLocalStorage = '@GIPIClientCardMessage';
                    const savedMessage = WhatsappContactDialogComponent.getMessage(keyLocalStorage);
                    const whatsappContact = new WhatsappContact();
                    whatsappContact.recipientName = this.client.name;
                    whatsappContact.recipientPhone = recipientPhoneAux;
                    whatsappContact.message = !StringUtil.isEmpty(savedMessage) ? savedMessage : '';
                    this._dialogService.open({
                        componentOrTemplateRef: WhatsappContactDialogComponent,
                        data: whatsappContact,
                        width: '55rem',
                        maxHeight: '55rem',
                    }).afterClosed().toPromise().then((message) => {
                        if (!StringUtil.isEmpty(message)) {
                            WhatsappContactDialogComponent.saveMessage(keyLocalStorage, message);
                        }
                    });
                }
            }
            catch (e) {
                this.loading = false;
                this.addErrorMessage(e);
            }
        });
    }
}
